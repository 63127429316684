"use client"

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

export const pageview = (
  company: string,
  subCompany: string,
  project: string,
  language: string,
) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "page properties",
      company_name: company,
      sub_company_name: subCompany,
      project_type: project,
      language: language,
    })
  }
}

export const mainMenuClick = (text: string, category: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "main menu",
      click_text: text, // The text displayed on the button that was clicked.
      category1: category, // Category name
      element_name: "תפריט ראשי", // The name of the UI element that was interacted with.
    })
  }
}

export const footerMenuClick = (text: string, category: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "footer",
      click_text: text, // The text displayed on the button that was clicked.
      category1: category, // Category name
      element_name: "תפריט ראשי", // The name of the UI element that was interacted with.
    })
  }
}

export const searchItemClick = (text: string | undefined, searchText: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "main search",
      click_text: text, // The text of the selected result from the autocomplete
      search_text: searchText, // The text displayed on the button that was clicked.
      element_name: "חיפוש ראשי",
    })
  }
}

export const activeArmsClick = (text: string, title: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "page strip",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: title, // The name of the UI element that was interacted with.
    })
  }
}

export const actionButtonClick = (text: string, elementName: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "page strip",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: elementName, // The name of the UI element that was interacted with.
    })
  }
}

export const pageStripClick = (text: string, title: string | undefined) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "page strip",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: title, // The name of the UI element that was interacted with.
    })
  }
}

export const articlesClick = (text: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "button click",
      click_text: text, // Title or description associated with the clicked text.
      element_name: "כניסה למאמר", // The name of the UI element that was interacted with.
    })
  }
}

export const aboutUsFilter = (text: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "filter click",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: "פילטר הנהלה", // The elements name according to their strip titles.
    })
  }
}

export const filterClick = (text: string, name: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "filter click",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: name, // The name of the element in the filter mapping table in the settings section
    })
  }
}

export const faqClickOpen = (text: string, name: string, sub: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "faq click",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: name, // The name of the element in the filter mapping table in the settings section.
      sub_click_text: sub,
    })
  }
}

export const faqClickClose = (text: string, name: string, sub: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "faq click",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: name, // The name of the element in the filter mapping table in the settings section.
      sub_click_text: sub,
    })
  }
}

export const iconClick = (text: string, value: string, name: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "page strip",
      click_text: text, // The text displayed on the button that was clicked.
      sub_click_text: value, // phone/address associated with the clicked text.
      element_name: name, // The name of the form
    })
  }
}

export const submitClick = (text: string, name: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "join form click",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: name, // The name of the form
    })
  }
}

export const submitError = (text: string, name: string, error: string | undefined) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "error join form",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: name, // The name of the UI element that was interacted with.
      error_name: error, // Error name – not all details filled, terms of use not approved.
    })
  }
}

export const submitSuccess = (text: string, name: string, approve: boolean) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "join form success",
      click_text: text, // The text displayed on the button that was clicked.
      element_name: name, // The name of the UI element that was interacted with.
      terms_of_use: approve, // TRUE / FALSE indication based on the user's choice.
    })
  }
}

export const jobFilter = (text: string, name: string, element: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "filter click",
      click_text: text, //The text displayed on the button that was clicked
      category1: name, // Category name – Field name
      element_name: element, // The name of the element that was interacted with
    })
  }
}

export const jobClick = (text: string, subText: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "button click",
      click_text: text, //The text displayed on the button that was clicked
      sub_click_text: subText, // Article name displayed in the teaser
      element_name: "כניסה למשרה", // The name of the element that was interacted with
    })
  }
}

export const contactButtonClick = (text: string, category: string, element: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "button click",
      click_text: text, // Title or description associated with the clicked text.
      category1: category, // Category name
      element_name: element, // The name of the UI element that was interacted with.
    })
  }
}

export const buttonClick = (text: string, element: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "button click",
      click_text: text, // Title or description associated with the clicked text.
      element_name: element, // The name of the UI element that was interacted with.
    })
  }
}

export const jobFormClick = (text: string, type: string, element: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "join form click",
      click_text: text, // The text displayed on the button that was clicked
      job_type: type, // Type of job associated with the interaction.
      element_name: element, // Form name specified in the form mapping settings
    })
  }
}

export const jobFormErrorClick = (
  text: string,
  type: string,
  element: string,
  error: string | undefined,
) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "error join form",
      click_text: text, // Button text
      element_name: element, // Form name specified in the form mapping settings
      job_type: type, // Type of job associated with the interaction.
      error_name: error, // Error name – Not all details filled, terms of use not approved/Resume not submitted
    })
  }
}

export const jobFormSuccessClick = (
  text: string,
  type: string,
  element: string,
  terms: boolean,
) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "join form success",
      click_text: text, // Button text
      element_name: element, // Form name specified in the form mapping settings
      job_type: type, // Type of job associated with the interaction.
      terms_of_use: terms, // Clicked element true / False
    })
  }
}

export const projectSearch = (text: string | undefined, searchText: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "project search",
      click_text: text, // The text of the selected result from the autocomplete
      search_text: searchText, // The text displayed on the button that was clicked.
      element_name: "חיפוש פרויקט",
    })
  }
}

export const choosenProject = (text: string | undefined, apartment_type: string | undefined) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "chosen project",
      click_text: text, // The text of the selected result from the autocomplete
      apartment_type: apartment_type, // The text displayed on the button that was clicked.
      element_name: "פילטר מתקדם פרויקטים בשיווק",
    })
  }
}

export const submenuClick = (text: string | undefined, apartment_type: string | undefined) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "project sub menu",
      click_text: text, // The text of the selected result from the autocomplete
      apartment_type: apartment_type, // The text displayed on the button that was clicked.
      element_name: "תפריט משני בהצעות פרויקטים",
    })
  }
}

export const chosenProjectType = (text: any, apartment_type: string | undefined, subText: any) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "project search",
      click_text: text, // The text of the selected result from the autocomplete
      apartment_type: apartment_type, // The text displayed on the button that was clicked.
      sub_click_text: subText,
      element_name: "עמוד פרויקטים  - הצעות",
    })
  }
}

export const apartmentPageStrip = (text: any, element: any) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "page strip",
      click_text: text, // The text displayed on the button that was clicked

      element_name: element, // The name of the UI element that was interacted with.
    })
  }
}
