"use client"

import styled from "@emotion/styled"
import Link from "next/link"
import Container from "./Container"
import NewText from "./NewText/NewText"
import TitleWithLine from "./TitleWithLine/TitleWithLine"
import { ReadMoreIcon } from "./icons"
import { sharedColors as colors } from "./theme/colors"

const StyledPadding = styled.div<{ $paddingTop?: number }>`
  padding: ${(p) => (p.$paddingTop ? `${p.$paddingTop}px 0` : "32px 0")};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 50px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: ${(p) => (p.$paddingTop ? `${p.$paddingTop}px 0` : "120px 0")};
  }
`

const StyledCTA = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.colors.blue};
  text-decoration: none;
  margin-top: 12px;
  font-size: 16px;
  line-height: 20px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 12px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: 18px;
    line-height: 25.2px;
    margin-top: 20px;
  }
`

const StyledTextWrapper = styled.div`
  margin-top: 12px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 12px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    margin-top: 18px;
  }
`

const StyledRichWrapper = styled.div`
  p {
    font-size: 18px;
    line-height: 28px;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
      line-height: 25px;
    }
    ${(props) => props.theme.breakpoints.up("lg")} {
      font-size: 28px;
      line-height: 40px;
    }
  }
`

type QuoteData = {
  __typename: string
  blockType: string
  properties: Array<{
    __typename: string
    propertyName: string
    propertyValue: {
      value?: string
      valueType: string
      richText?: string
      links?: Array<{
        name: string
        url: string
      }>
    }
  }>
}

type Props = {
  data?: QuoteData
  titleFontSize?: number
  titleColor?: string
  subtitleFontSize?: number
  subtitleColor?: string
  paddingTop?: number
  titleWithLine?: boolean
  titlePropertyName?: string
  textPropertyName?: string
  linkPropertyName?: string
  textType?: string
  props?: any
}

function Quote(props: Props) {
  const {
    data,
    titleFontSize,
    titleColor,
    subtitleFontSize,
    subtitleColor,
    paddingTop,
    titleWithLine,
    titlePropertyName = "quoteTitle",
    textPropertyName = "quoteText",
    linkPropertyName = "quoteLink",
    textType,
  } = props

  let extractedTitle = ""
  let extractedSubtitle = ""
  let extractedReadMore = { url: "", text: "" }

  if (data) {
    data.properties.forEach((property) => {
      const { propertyName, propertyValue } = property
      if (propertyName === titlePropertyName) {
        extractedTitle = propertyValue.value || ""
      } else if (propertyName === textPropertyName) {
        extractedSubtitle = propertyValue.value || propertyValue.richText || ""
      } else if (propertyName === linkPropertyName) {
        const link = propertyValue.links && propertyValue.links[0]
        if (link) {
          extractedReadMore = {
            url: link.url,
            text: link.name,
          }
        }
      }
    })
  }

  return (
    <StyledPadding $paddingTop={paddingTop}>
      <Container>
        {!titleWithLine ? (
          <NewText
            mobileFontSize={titleFontSize ? titleFontSize : 28}
            desktopFontSize={titleFontSize ? titleFontSize : 80}
            component="h2"
            desktopLineHeight="128%"
            desktopFontWeight="500"
            color={titleColor ? titleColor : colors.blue}
          >
            {extractedTitle}
          </NewText>
        ) : (
          <TitleWithLine
            titleFontSize={titleFontSize}
            titleColor={titleColor}
            title={extractedTitle}
          />
        )}
        <StyledTextWrapper>
          {textType !== "rich" && (
            <NewText
              mobileFontSize={subtitleFontSize ? subtitleFontSize : 28}
              desktopFontSize={subtitleFontSize ? subtitleFontSize : 80}
              desktopLineHeight="128%"
              desktopFontWeight="400"
              component="p"
              color={subtitleColor ? subtitleColor : colors.black}
              smallDesktopFontSize={18}
              smallDesktopLineHeight="25px"
            >
              {extractedSubtitle}
            </NewText>
          )}
          {textType === "rich" && (
            <StyledRichWrapper dangerouslySetInnerHTML={{ __html: extractedSubtitle }} />
          )}
        </StyledTextWrapper>
        {extractedReadMore.url && extractedReadMore.text && (
          <StyledCTA href={extractedReadMore.url}>
            {extractedReadMore.text}
            <ReadMoreIcon />
          </StyledCTA>
        )}
      </Container>
    </StyledPadding>
  )
}

export default Quote
