"use client"
import styled from "@emotion/styled"
import Image from "next/image"
import { useState } from "react"

import NewText from "../NewText/NewText"
import { sharedColors } from "../theme/colors"

import { IconButton } from "@mui/material"
import Link from "next/link"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import { getNonUmbracoUrl, getUmbracoMediaUrl } from "utils"
import ButtonWithArrow from "../ButtonWithArrow"
import { Desktop } from "../common/bit/MediaQueries"
import { LeftArrow, RightArrow } from "../icons"

const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`
const StyledPaginatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
`

const StyledBullet = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 2px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? sharedColors.black : "#C1B9B0")};
`

type Props = {
  data: any
}

export const ActivitySwiper: React.FC<Props> = ({ data }) => {
  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  return (
    <>
      <Swiper
        onSwiper={setSwiperRef}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex)
        }}
      >
        {data.map((item: any, index: number) => (
          <SwiperSlide virtualIndex={index} key={index.toString()}>
            <Image
              src={getUmbracoMediaUrl(item.logo)}
              width={100}
              height={100}
              alt="logo"
              style={{
                objectFit: "contain",
                marginInlineEnd: "auto",
                width: "auto",
                height: 100,
              }}
            />
            <NewText
              desktopFontSize={14}
              desktopFontWeight="400"
              desktopLineHeight="100%"
              mobileFontSize={12}
              mobileFontWeight="400"
              mobileLineHeight="125%"
              component="div"
              color={sharedColors.black}
              dangerouslySetInnerHTML={{ __html: item.content }}
              maxWidth={"624px"}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledBottomContainer>
        <StyledPaginatorContainer>
          <Desktop>
            <IconButton
              onClick={() => {
                swiperRef.slidePrev()
              }}
            >
              <RightArrow />
            </IconButton>
          </Desktop>
          <StyledBulletsContainer>
            {data.map((_: any, index: number) => (
              <StyledBullet
                key={index.toString()}
                onClick={() => {
                  swiperRef.slideTo(index)
                }}
                active={index === activeIndex}
              />
            ))}
          </StyledBulletsContainer>
          <Desktop>
            <IconButton
              onClick={() => {
                swiperRef.slideNext()
              }}
            >
              <LeftArrow />
            </IconButton>
          </Desktop>
        </StyledPaginatorContainer>
        <Link href={getNonUmbracoUrl(data[activeIndex]?.link[0]?.url) || ""}>
          <ButtonWithArrow title="לאשטרום קבלנות" />
        </Link>
      </StyledBottomContainer>
    </>
  )
}
export default ActivitySwiper
