import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import GET_PAGES_WITH_OLD_URLS from "apollo/queries/GET_PAGES_WITH_OLD_URLS"
import { getNonUmbracoUrl } from "./getNonUmbracoUrl"

interface Link {
  url: string
  [key: string]: any
}

interface PropertyValue {
  links: Link[]
  [key: string]: any
}

interface Property {
  propertyName: string
  propertyValue: PropertyValue
  [key: string]: any
}

const GRAPHQL_URL = process.env.GRAPHQL_URL
if (!GRAPHQL_URL) {
  throw new Error("Please provide the GRAPHQL_URL environment variable.")
}

export const getClientServerSide = new ApolloClient({
  link: new HttpLink({
    uri: GRAPHQL_URL,
    fetch,
  }),
  cache: new InMemoryCache(),
})

export type Redirect = {
  source: string
  destination: string
}

declare global {
  interface Global {
    redirectsCache: {
      lastFetch: number
      data: Redirect[] | null
    }
  }
}

;(global as Global & typeof globalThis).redirectsCache = (global as Global & typeof globalThis)
  .redirectsCache || {
  lastFetch: 0,
  data: null,
}

export async function getCachedRedirects(pathBase: string): Promise<Redirect[] | null> {
  const oneDayInMillis = 60 * 60 * 1000 // 1 hours in milliseconds
  const now = Date.now()
  const globalObject = global as Global & typeof globalThis
  // Check if the cache is older than a day or empty
  if (
    !globalObject.redirectsCache.data ||
    now - globalObject.redirectsCache.lastFetch > oneDayInMillis
  ) {
    // Fetch data for Hebrew culture
    const { data: heData } = await getClientServerSide.query({
      query: GET_PAGES_WITH_OLD_URLS,
      variables: {
        route: `${pathBase}`,
        culture: "he-il",
      },
    })

    // Fetch data for English culture
    const { data: enData } = await getClientServerSide.query({
      query: GET_PAGES_WITH_OLD_URLS,
      variables: {
        route: `${pathBase}`,
        culture: "en-us",
      },
    })
    // eslint-disable-next-line no-console
    console.log("FETCH REDIRECTS")
    const extractOldUrls = (nodes: any, isEnglish = false) => {
      const urls: Redirect[] = []
      nodes.forEach((node: any) => {
        node.properties.forEach((prop: any) => {
          if (prop.propertyName === "oldUrls" && prop.propertyValue.links) {
            const updatedLinks: Link[] = prop.propertyValue.links.map((link: Link): Link => {
              if (link.url.startsWith("http://") || link.url.startsWith("https://")) {
                const url = new URL(link.url, GRAPHQL_URL)
                if (url.origin !== GRAPHQL_URL) {
                  return {
                    ...link,
                    url: url.pathname,
                  }
                }
              }
              return link
            })
            updatedLinks.forEach((link: any) => {
              const source = link.url
                .replace(/\/$/, "")
                .replace(/\/he-IL$/, "")
                .replace(/\/en-US$/, "")
              const destination = getNonUmbracoUrl(
                node.url
                  .replace(/\/$/, "")
                  .replace(/\/he-IL$/, "")
                  .replace(/\/en-US$/, ""),
              )

              const redirect: Redirect = {
                destination,
                source: source,
              }

              urls.push(redirect)
            })
          }
        })

        // Recursively extract from children if they exist
        if (node.children && node.children.length > 0) {
          urls.push(...extractOldUrls(node.children, isEnglish))
        }
      })
      return urls
    }

    // Extract URLs from both Hebrew and English responses
    const heUrls = extractOldUrls(heData.components.edges.map((edge: any) => edge.node))
    const enUrls = extractOldUrls(
      enData.components.edges.map((edge: any) => edge.node),
      true,
    )

    console.log(enUrls)
    // Combine both arrays
    const oldUrls = [...enUrls, ...heUrls]
    // console.log({ oldUrls })

    globalObject.redirectsCache = {
      lastFetch: now,
      data: oldUrls,
    }
  }

  return globalObject.redirectsCache.data
}

export {}

// Function to replace dynamic path placeholders, or return direct destination for plain URLs
export const replacePath = (destination: string, params?: Record<string, string>): string => {
  if (!params) {
    return destination
  } // Return the direct destination if there are no parameters to replace
  return destination.replace(/:\w+\*/g, (match) => {
    const key = match.slice(1, -1) // Remove the colon and asterisk
    return params[key] || "" // Replace with the parameter value or empty string
  })
}

// Extract dynamic segments from the path or return {} for a direct match
export const extractParams = (pattern: string, path: string): Record<string, string> | null => {
  // Normalize the paths for comparison
  const source = decodeURI(pattern.toLowerCase().replace(/^\/|\/$/g, ""))
  const current = decodeURI(path.toLowerCase().replace(/^\/|\/$/g, ""))
  if (!source.includes(":")) {
    const match = source === current
    return match ? {} : null // Direct match for plain URLs
  }
  const regex = new RegExp("^" + source.replace(/:\w+\*/g, "(.*)") + "$", "i") // 'i' for case-insensitive regex
  const match = current.match(regex)

  return match ? { path: match[1] } : null // Assuming only one dynamic segment for simplicity
}
