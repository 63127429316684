"use client"
import styled from "@emotion/styled"
import NewText from "../NewText/NewText"
import { sharedColors } from "../theme/colors"

const StyledListWrapper = styled.div`
  margin-top: 12px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 20px;
  }
`

const StyledUl = styled.ul`
  margin-top: 12px;
  padding-left: 20px;
`

const StyledLi = styled.li`
  list-style-type: decimal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 4px;
  padding-left: 12px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 12px;
    line-height: 18px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: 16px;
    line-height: 24px;
  }
`

type ListProps = {
  title: string
  marginTop?: number
  dummyList: string[]
  children?: JSX.Element
}

export const List = ({ title, dummyList, children }: ListProps) => {
  return (
    <StyledListWrapper>
      <NewText
        component={"p"}
        desktopFontSize={16}
        desktopLineHeight={"23px"}
        desktopFontWeight={600}
        color={sharedColors.blue}
        smallDesktopFontSize={14}
        smallDesktopLineHeight="20px"
      >
        {title}
      </NewText>
      <StyledUl>
        {dummyList.map((list, index) => (
          <StyledLi key={index}>{list}</StyledLi>
        ))}
      </StyledUl>
      {children ? children : null}
    </StyledListWrapper>
  )
}

export default List
