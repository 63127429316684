import { SVGProps } from "react"
const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
    <rect width={38} height={38} x={1} y={1} fill="#fff" rx={19} />
    <rect width={38} height={38} x={1} y={1} stroke="#000" strokeWidth={2} rx={19} />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13 12.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5ZM14.25 15a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="M19 14.25a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H19ZM21.75 25a.75.75 0 0 0-.75-.75H11a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 .75-.75Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M27 22.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5ZM25.75 25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default FilterIcon
