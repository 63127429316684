import { GetPageQuery } from "apollo"
import { extractMetaTags } from "./metaTags"

const JsonLdSchema = ({ data }: { data: GetPageQuery }) => {
  const pageData = extractMetaTags({ itemsData: data })
  const jsonLd = pageData?.jsonPdSchema
  if (!jsonLd) {
    return null
  }

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonLd }} />
}

export default JsonLdSchema
