import { SVGProps } from "react"
const PdfIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_6544_12469)">
      <path
        d="M0.667969 8.21094L2.37602 9.8969V8.21094H0.667969Z"
        fill="#951913"
      />
      <path
        d="M3.43468 0.834635H12.6105L16.9178 5.08625V18.4834C16.9178 18.8561 16.6097 19.168 16.2187 19.168H3.43468C3.04373 19.168 2.73564 18.8561 2.73564 18.4834V1.51925C2.73564 1.14649 3.04373 0.834635 3.43468 0.834635Z"
        fill="white"
        stroke="black"
        strokeWidth="0.833333"
      />
      <path
        d="M13.2549 5.43643C13.4758 5.43822 13.7069 5.40239 13.935 5.37193C14.4941 5.29608 15.0525 5.22083 15.6116 5.14499C16.1858 5.06735 16.76 4.9921 17.3342 4.91446L12.7812 0.417969C12.6596 1.20451 12.538 1.99105 12.4169 2.7776C12.3625 3.13175 12.3074 3.4865 12.253 3.84065C12.1907 4.24437 12.1023 4.63436 12.3528 4.99628C12.5912 5.34088 12.9101 5.43464 13.2555 5.43703L13.2549 5.43643Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M16.319 4.4954H13.7403C13.4358 4.4954 13.1979 4.25283 13.1979 3.96547V1.4147L16.319 4.4954Z"
        fill="white"
        stroke="black"
        strokeWidth="0.833333"
      />
      <g opacity="0.08">
        <path
          d="M10.8937 9.16797H1.64262L0.669312 8.21094V4.16797H10.8937C11.2302 4.16797 11.5026 4.44929 11.5026 4.79684V8.5391C11.5026 8.88665 11.2302 9.16797 10.8937 9.16797Z"
          fill="black"
        />
      </g>
      <path
        d="M10.5062 8.21142H0.669312V3.29688H10.5062C10.8523 3.29688 11.1324 3.57339 11.1324 3.915V7.5933C11.1324 7.93491 10.8523 8.21142 10.5062 8.21142Z"
        fill="#C92418"
      />
      <path
        d="M4.50778 5.32207C4.50778 5.53289 4.44546 5.71086 4.31961 5.85539C4.17985 6.01545 3.96566 6.09547 3.67645 6.09547H3.12101V6.96085H2.59644V4.54688H3.67645C3.92875 4.54688 4.12902 4.61317 4.27787 4.74575C4.43094 4.88371 4.50718 5.07601 4.50718 5.32207H4.50778ZM3.99712 5.32207C3.99712 5.18053 3.95114 5.08079 3.85857 5.02346C3.78475 4.97926 3.67584 4.95717 3.53245 4.95717H3.12101V5.68578H3.53245C3.67342 5.68578 3.78233 5.66308 3.85857 5.6171C3.95114 5.56275 3.99712 5.46421 3.99712 5.32207Z"
        fill="white"
      />
      <path
        d="M6.95868 5.74192C6.95868 6.10264 6.86973 6.3911 6.69185 6.60729C6.49944 6.8432 6.22475 6.96085 5.86898 6.96085H4.83435V4.54688H5.86898C6.19208 4.54688 6.45104 4.64363 6.64647 4.83772C6.85461 5.04615 6.95928 5.34775 6.95928 5.74192H6.95868ZM6.42865 5.79268C6.42865 5.26056 6.19813 4.99419 5.73829 4.99419H5.35832V6.51532H5.82905C6.00028 6.51532 6.13944 6.46217 6.24653 6.35527C6.36754 6.23283 6.42865 6.04531 6.42865 5.79268Z"
        fill="white"
      />
      <path
        d="M9.00526 4.99419H7.84599V5.5526H8.84674V5.96289H7.84599V6.96085H7.32141V4.54688H9.00526V4.99419Z"
        fill="white"
      />
      <path
        d="M5.66797 10.8359H14.0013"
        stroke="black"
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
      <path
        d="M5.66797 13.3359H14.0013"
        stroke="black"
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
      <path
        d="M5.66797 15.8359H10.668"
        stroke="black"
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6544_12469">
        <rect
          width="16.6667"
          height="19.1667"
          fill="white"
          transform="translate(0.666626 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default PdfIcon
