"use client"
import styled from "@emotion/styled"

import { useCallback } from "react"
import { extractProperty, getNonUmbracoUrl } from "utils"
import Container from "../Container"
import NewText from "../NewText/NewText"
import { sharedColors } from "../theme/colors"
import Timeline from "./TimeLine"

const StyledContainer = styled.div<{ homepage?: boolean }>`
  padding: 32px 0 0 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => (props.homepage ? "60px 0 0 0" : "40px 0 80px 0")};
  }
`

type Props = {
  data: any
  locale: string
  homepage?: boolean
}

export const History: React.FC<Props> = ({ data, locale, homepage }) => {
  const conetntTitle = extractProperty(data, "conetntTitle")
  const innerText = extractProperty(data, "innerText")
  const innerSlider = extractProperty(data, "innerSlider")
  const linkForMore = extractProperty(data, "linkForMore")

  const dataToMap = useCallback(() => {
    if (innerSlider) {
      return innerSlider?.map((item: any) => ({
        image: extractProperty(item, "slidImage") || "/",
        title: extractProperty(item, "slidYears"),
        description: extractProperty(item, "slidContent"),
      }))
    }
  }, [innerSlider])

  return (
    <Container>
      <StyledContainer homepage={homepage}>
        <NewText
          desktopFontSize={32}
          desktopFontWeight="400"
          desktopLineHeight="36px"
          mobileFontSize={32}
          mobileFontWeight="400"
          mobileLineHeight={"28px"}
          component="h2"
          color={"black"}
        >
          {conetntTitle ? conetntTitle : "אודות הקבוצה"}
        </NewText>

        <NewText
          desktopFontSize={18}
          desktopFontWeight="400"
          desktopLineHeight="26px"
          mobileFontSize={16}
          mobileFontWeight="400"
          mobileLineHeight={"23px"}
          component="div"
          color={sharedColors.black}
          maxWidth={"800px"}
          marginTop={"16px"}
          marginBottom={"24px"}
          dangerouslySetInnerHTML={{
            __html: innerText,
          }}
        />
        <Timeline
          data={dataToMap}
          locale={locale}
          readMore={{
            text: linkForMore?.[0]?.name,
            url: getNonUmbracoUrl(linkForMore?.[0]?.url),
          }}
          conetntTitle={conetntTitle}
        />
      </StyledContainer>
    </Container>
  )
}

export default History
