import styled from "@emotion/styled"
import NewText from "../../NewText/NewText"
import { Desktop } from "../../common/bit/MediaQueries"
import { MintIcon } from "../../icons"
import { sharedColors } from "../../theme/colors"

const StyledGap = styled.div`
  display: flex;
  gap: 40px;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 24px;
    width: auto;
  }
`

type Props = { englishDesktop: any; text: string }

function Copyright({ text }: Props) {
  return (
    <StyledGap>
      <NewText desktopFontSize={14} color={sharedColors.white} component="p" mobileFontSize={12}>
        {text}
      </NewText>
      <Desktop>
        <MintIcon />
      </Desktop>
    </StyledGap>
  )
}

export default Copyright
