"use client"
import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import { Container, NewText, TransitionUp, useInViewConfig } from "./"
import { delayTime } from "./transitions/utils"

import { extractImage, extractProperty } from "utils"
import ImageWithAlt from "./ImageWithAlt"

const StyledContainer = styled.div`
  padding: 32px 0 0 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 60px 0 0 0;
  }
`

const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 32px;
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 40px;
    row-gap: 36px;
  }
`

const StyledItemContainer = styled.div`
  padding-bottom: 16px;
`

type Props = {
  data: any
}

export const GroupValues: React.FC<Props> = ({ data }) => {
  const title = extractProperty(data, "title")
  const { ref, inView } = useInView(useInViewConfig)
  const groupValuesItem = extractProperty(data, "groupValuesItem").map((item: any) => {
    return {
      itemTitle: extractProperty(item, "itemTitle"),
      itemImage: extractImage(item, "itemImage"),
      itemContent: extractProperty(item, "itemContent"),
    }
  })

  return (
    <Container>
      <StyledContainer>
        <NewText
          desktopFontSize={32}
          desktopFontWeight="400"
          desktopLineHeight="110%"
          mobileFontSize={24}
          mobileFontWeight="400"
          mobileLineHeight={"112.5%"}
          component="h1"
          color={"black"}
          marginBottom="40px"
        >
          {title}
        </NewText>
        <StyledGridContainer ref={ref}>
          {groupValuesItem.map((item: any, key: any) => (
            <StyledItemContainer key={key.toString()}>
              <TransitionUp $inView={inView} $delay={key * delayTime * 0.5}>
                <ImageWithAlt
                  image={item.itemImage}
                  width={80}
                  height={80}
                  style={{
                    objectFit: "contain",
                    borderRadius: "50%",
                    marginBlockEnd: 16,
                  }}
                />
              </TransitionUp>
              <TransitionUp $inView={inView} $delay={key * delayTime * 0.5 + 0.2}>
                <NewText
                  desktopFontSize={18}
                  desktopFontWeight={600}
                  desktopLineHeight={"26px"}
                  mobileFontSize={16}
                  mobileFontWeight="600"
                  mobileLineHeight={"23px"}
                  component="p"
                  color={"black"}
                  style={{ marginBlockEnd: 4 }}
                >
                  {item.itemTitle}
                </NewText>
              </TransitionUp>
              <TransitionUp $inView={inView} $delay={key * delayTime * 0.5 + 0.4}>
                <NewText
                  desktopFontSize={16}
                  desktopFontWeight={400}
                  desktopLineHeight={"23px"}
                  mobileFontSize={14}
                  mobileFontWeight="400"
                  mobileLineHeight={"20px"}
                  component="p"
                  color={"black"}
                >
                  {item.itemContent}
                </NewText>
              </TransitionUp>
            </StyledItemContainer>
          ))}
        </StyledGridContainer>
      </StyledContainer>
    </Container>
  )
}

export default GroupValues
