const FilePdfIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6032_32299)">
      <path d="M2 9.85156L4.04966 11.8747V9.85156H2Z" fill="#951913" />
      <path
        d="M5.31932 1H16.3303L21.4991 6.10194V22.1785C21.4991 22.6258 21.1293 23 20.6602 23H5.31932C4.85018 23 4.48047 22.6258 4.48047 22.1785V1.82153C4.48047 1.37423 4.85018 1 5.31932 1Z"
        fill="white"
        stroke="black"
      />
      <path
        d="M17.1036 6.52215C17.3686 6.5243 17.6459 6.4813 17.9197 6.44475C18.5905 6.35374 19.2607 6.26344 19.9316 6.17242C20.6206 6.07925 21.3096 5.98895 21.9987 5.89579L16.5351 0.5C16.3891 1.44385 16.2432 2.3877 16.098 3.33155C16.0326 3.75654 15.9666 4.18224 15.9012 4.60722C15.8264 5.09169 15.7204 5.55967 16.021 5.99397C16.3071 6.40749 16.6897 6.52 17.1043 6.52287L17.1036 6.52215Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M20.7803 5.39292H17.6858C17.3205 5.39292 17.035 5.10183 17.035 4.757V1.69608L20.7803 5.39292Z"
        fill="white"
        stroke="black"
      />
      <g opacity="0.08">
        <path
          d="M14.2692 10.9961L3.16797 10.9961L2 9.84766V4.99609H14.2692C14.6731 4.99609 15 5.33368 15 5.75074V10.2414C15 10.6585 14.6731 10.9961 14.2692 10.9961Z"
          fill="black"
        />
      </g>
      <path
        d="M13.8043 9.85058H2V3.95312H13.8043C14.2196 3.95312 14.5557 4.28494 14.5557 4.69488V9.10883C14.5557 9.51876 14.2196 9.85058 13.8043 9.85058Z"
        fill="#C92418"
      />
      <path
        d="M6.60612 6.38336C6.60612 6.63634 6.53133 6.84991 6.38031 7.02334C6.21259 7.21541 5.95557 7.31144 5.60851 7.31144H4.94199V8.34989H4.3125V5.45312H5.60851C5.91128 5.45312 6.15161 5.53268 6.33022 5.69178C6.51391 5.85733 6.60539 6.08809 6.60539 6.38336H6.60612ZM5.99332 6.38336C5.99332 6.21351 5.93814 6.09383 5.82706 6.02503C5.73848 5.97199 5.60779 5.94548 5.43571 5.94548H4.94199V6.81981H5.43571C5.60488 6.81981 5.73557 6.79258 5.82706 6.73739C5.93814 6.67218 5.99332 6.55393 5.99332 6.38336Z"
        fill="white"
      />
      <path
        d="M9.54724 6.88718C9.54724 7.32004 9.44051 7.66619 9.22705 7.92563C8.99616 8.20871 8.66653 8.34989 8.23961 8.34989H6.99805V5.45312H8.23961C8.62732 5.45312 8.93807 5.56923 9.17259 5.80214C9.42236 6.05226 9.54796 6.41418 9.54796 6.88718H9.54724ZM8.91121 6.94809C8.91121 6.30954 8.63458 5.98991 8.08278 5.98991H7.62681V7.81526H8.19169C8.39716 7.81526 8.56415 7.75148 8.69267 7.62319C8.83788 7.47628 8.91121 7.25124 8.91121 6.94809Z"
        fill="white"
      />
      <path
        d="M12.0029 5.98991H10.6118V6.65999H11.8127V7.15234H10.6118V8.34989H9.9823V5.45312H12.0029V5.98991Z"
        fill="white"
      />
      <path
        d="M8 13H18"
        stroke="black"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M8 16H18"
        stroke="black"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M8 19H14"
        stroke="black"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6032_32299">
        <rect
          width="20"
          height="23"
          fill="white"
          transform="translate(2 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default FilePdfIcon
