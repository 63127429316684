const RadioUnCheckedIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <circle
      cx="12.25"
      cy="12"
      r="11.5"
      fill="white"
      stroke={props.error ? "rgb(201, 36, 24)" : "#6F6F6F"}
    />
  </svg>
)
export default RadioUnCheckedIcon
