"use client"
import styled from "@emotion/styled"
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material"
import { useLocale } from "next-intl"
import Image from "next/image"
import Link from "next/link"
import React from "react"
import { footerMenuClick, getNonUmbracoUrl } from "utils"
import NewText from "./NewText/NewText"
import { sharedColors } from "./theme/colors"

const StyledListItemButton = styled(ListItemButton)`
  a {
    text-decoration: none;
  }
`
const StyledButton = styled(ListItemButton)`
  background: transparent;
  padding: 8px 0px;
  border-top: 1px solid rgba(111, 111, 111, 0.5);
`

type Props = {
  title: string
  children: { title: string; url: string }[]
  color?: string
}

export const FooterList: React.FC<Props> = ({ title, children, color = sharedColors.white }) => {
  const [open, setOpen] = React.useState(false)
  const locale = useLocale()

  const handleClick = () => {
    footerMenuClick(title, "")
    setOpen(!open)
  }

  return (
    <>
      <StyledButton onClick={handleClick}>
        <ListItemText>
          <NewText
            desktopFontSize={16}
            component="li"
            desktopLineHeight="20px"
            desktopFontWeight={open ? "600" : "400"}
            color={color}
            mobileFontSize={16}
            mobileLineHeight={"20px"}
          >
            {title}
          </NewText>
        </ListItemText>
        {children.length > 0 ? (
          open ? (
            <Image
              src={
                color === sharedColors.white
                  ? "/icons/arrow-down-white.svg"
                  : "/icons/arrow-down.svg"
              }
              width={24}
              height={24}
              alt="down"
            />
          ) : (
            <Image
              src={
                color === sharedColors.white
                  ? "/icons/arrow-left-white.svg"
                  : "/icons/arrow-left.svg"
              }
              style={{ transform: locale === "he" ? "none" : "rotate(180deg)" }}
              width={24}
              height={24}
              alt="left"
            />
          )
        ) : null}
      </StyledButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((item, index) => (
            <StyledListItemButton sx={{ padding: "8px" }} key={index.toString()}>
              <Link
                href={getNonUmbracoUrl(item?.url) || "#"}
                onClick={() => footerMenuClick(item?.title, title)}
              >
                <ListItemText>
                  <NewText
                    desktopFontSize={16}
                    component="li"
                    desktopLineHeight="20px"
                    desktopFontWeight="400"
                    color={color}
                    mobileFontSize={14}
                  >
                    {item?.title}
                  </NewText>
                </ListItemText>
              </Link>
            </StyledListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default FooterList
