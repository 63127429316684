"use client"

import styled from "@emotion/styled"
import { Box, Button, Link } from "@mui/material"
import { useInView } from "react-intersection-observer"
import { extractImage, extractProperty, getNonUmbracoUrl, getUmbracoPDFUrl } from "utils"
import { actionButtonClick } from "utils/gtm"
import StockDto from "../../packages/pages/Homepage/StockDto"
import Container from "./Container"
import ImageWithAlt from "./ImageWithAlt"
import NewText from "./NewText/NewText"
import { Desktop, Mobile, useIsDesktop } from "./common/bit/MediaQueries"
import { sharedColors } from "./theme/colors"
import { useTransitionUpStyles } from "./transitions/useTransitionUpStyles"
import { delayTime, styleObjectToCss, useInViewConfig } from "./transitions/utils"

const StyledLink = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`
const StyledAbsoluteContainer = styled.div<{
  imgTxtPadding?: boolean
  homepage?: boolean
}>`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  &.image-text-block-no-margin {
    padding-top: ${(props) => (props.imgTxtPadding ? "0" : "40px")};
    padding-bottom: 40px;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding-top: ${(props) => (props.homepage ? "60px" : "40px")};
      padding-bottom: ${(props) => (props.homepage ? "0" : "80px")};
    }
  }
  &.image-text-block-no-margin + .image-text-block-no-margin {
    padding-top: ${(props) => (props.homepage ? "40px" : "0")};
    padding-bottom: 40px;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding-bottom: ${(props) => (props.homepage ? "0" : "80px")};
    }
  }
`

const StyledContainer = styled.div<{
  $isFlipped: boolean
}>`
  position: relative;
  margin: 0 auto;
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 60px;

    flex-direction: ${(p) => (p.$isFlipped ? "row" : "row-reverse")};
  }
`
const StyledMobileImageContainer = styled.div`
  width: 100%;
  position: relative;
  height: 412px;
  order: 1;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
    order: 0;
  }
`

const StyledContetnContainer = styled.div<{
  $isFlipped: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: ${(p) => (p.$isFlipped ? "33px" : 0)};
    margin-right: ${(p) => (p.$isFlipped ? "33px" : 0)};
    max-width: 570px;
  }
`

const StyledGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  gap: 8px;
`

const StyledStockDtoWrapper = styled.div``

type Props = {
  data: any
  locale: string
  noMargin?: boolean
  imgTxtPadding?: boolean
  lobby?: boolean
  homepage?: boolean
  stockBanner: any
  change: string
  lastUpdate: string
  cents?: string
  StockRate?: string
  alldata: any
  baseRate?: string
  page?: string
}
// eslint-disable-next-line complexity
export const ImageTextBlock: React.FC<Props> = ({
  data,
  locale,
  noMargin,
  imgTxtPadding,
  lobby,
  homepage,
  stockBanner,
  change,
  lastUpdate,
  cents,
  StockRate,
  baseRate,
  page,
}) => {
  const title = extractProperty(data, "headline")
  const image = extractImage(data, "image")
  const description = extractProperty(data, "body")
  const link = extractProperty(data, "link")
  const iconNextToTitle = extractImage(data, "iconNextToTitle")
  const { ref, inView } = useInView(useInViewConfig)

  const stockInfo = extractProperty(data, "stockInfo")

  const imageTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)
  const boxTransitionStyles = useTransitionUpStyles(inView, delayTime * 2)
  const href = link?.[0]?.url
  const hrefType = link?.[0]?.type
  const isDesktop = useIsDesktop()
  const isFlipped = isDesktop
    ? locale === "en"
      ? !extractProperty(data, "flip")
      : extractProperty(data, "flip")
    : extractProperty(data, "flip")

  return (
    <StyledAbsoluteContainer
      homepage={homepage}
      className={noMargin || homepage ? "image-text-block-no-margin" : ""}
      imgTxtPadding={imgTxtPadding}
      ref={ref}
    >
      <Container
        style={
          !noMargin && !lobby && !homepage
            ? {
                paddingTop: isDesktop ? "80px" : "40px",
                paddingBottom: isDesktop ? "80px" : "40px",
              }
            : lobby
            ? {
                paddingTop: isDesktop ? "" : "32px",
                paddingBottom: isDesktop ? "" : "32px",
              }
            : {}
        }
      >
        <StyledContainer $isFlipped={isFlipped}>
          <Desktop>
            <ImageWithAlt
              image={image}
              width={300}
              height={632}
              role="presentation"
              aria-hidden="true"
              style={{
                objectFit: "contain",
                width: "420px",
                height: "576px",

                ...imageTransitionStyles,
              }}
            />
          </Desktop>

          <StyledMobileImageContainer>
            <ImageWithAlt
              image={image}
              fill
              role="presentation"
              aria-hidden="true"
              style={{
                objectFit: "contain",
                objectPosition: isFlipped ? "right" : "left",
                ...imageTransitionStyles,
              }}
            />

            {/* </TransitionUp> */}
          </StyledMobileImageContainer>

          <StyledContetnContainer $isFlipped={isFlipped}>
            <StyledTitleContainer>
              {iconNextToTitle?.url && (
                <ImageWithAlt
                  image={iconNextToTitle}
                  height={40}
                  width={40}
                  style={{ marginInlineEnd: "8px" }}
                />
              )}

              <NewText
                desktopFontSize={40}
                desktopFontWeight="400"
                desktopLineHeight="44px"
                mobileFontSize={32}
                mobileFontWeight="400"
                mobileLineHeight={"36px"}
                component="h3"
                color={sharedColors.black}
                dangerouslySetInnerHTML={{ __html: title }}
                cssString={styleObjectToCss(imageTransitionStyles)}
                paddingLeft={stockInfo && !isDesktop && "16px"}
                paddingRight={stockInfo && !isDesktop && "16px"}
              />
            </StyledTitleContainer>
            <StyledGap>
              <NewText
                desktopFontSize={18}
                desktopFontWeight="400"
                desktopLineHeight="26px"
                mobileFontSize={16}
                mobileFontWeight="400"
                mobileLineHeight={"23px"}
                component="div"
                color={sharedColors.black}
                dangerouslySetInnerHTML={{ __html: description }}
                cssString={styleObjectToCss(descriptionTransitionStyles)}
                paddingLeft={stockInfo && !isDesktop && "16px"}
                paddingRight={stockInfo && !isDesktop && "16px"}
              />
              {stockInfo && (
                <StyledStockDtoWrapper>
                  <StockDto
                    stockBanner={stockBanner?.stockBanner}
                    change={change}
                    lastUpdate={lastUpdate}
                    cents={cents}
                    StockRate={StockRate}
                    baseRate={baseRate}
                    page={page}
                  />
                </StyledStockDtoWrapper>
              )}
              {href ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    ...boxTransitionStyles,
                    paddingLeft: stockInfo && !isDesktop && "16px",
                    paddingRight: stockInfo && !isDesktop && "16px",
                  }}
                >
                  <Desktop>
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ marginInlineEnd: "auto", fontSize: "18px", whiteSpace: "nowrap" }}
                      LinkComponent={StyledLink}
                      href={
                        hrefType === "MEDIA" ? getUmbracoPDFUrl(href) : getNonUmbracoUrl(href) || ""
                      }
                      target={link?.[0]?.target}
                      onClick={() =>
                        actionButtonClick(link[0]?.name, title.replace(/<[^>]+>/g, ""))
                      }
                    >
                      {link[0]?.name}
                    </Button>
                  </Desktop>
                  <Mobile>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ marginInlineEnd: "auto", fontSize: "16px" }}
                      LinkComponent={StyledLink}
                      href={
                        hrefType === "MEDIA" ? getUmbracoPDFUrl(href) : getNonUmbracoUrl(href) || ""
                      }
                      onClick={() =>
                        actionButtonClick(link[0]?.name, title.replace(/<[^>]+>/g, ""))
                      }
                    >
                      {link[0]?.name}
                    </Button>
                  </Mobile>
                </Box>
              ) : null}
            </StyledGap>
          </StyledContetnContainer>
        </StyledContainer>
      </Container>
    </StyledAbsoluteContainer>
  )
}

export default ImageTextBlock
