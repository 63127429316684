"use client"

import styled from "@emotion/styled"
import { Button, Collapse, List, ListItemButton, ListItemText, Menu, MenuItem } from "@mui/material"
import { useLocale } from "next-intl"
import Link from "next/link"
import { usePathname } from "next/navigation"
import * as React from "react"
import { useState } from "react"
import { extractMenuProperties, getNonUmbracoUrl, mainMenuClick } from "utils"
import NewText from "../NewText/NewText"
import Text from "../Text/Text"
import { Desktop, Mobile, useIsDesktop } from "../common/bit/MediaQueries"
import { MenuArrowDown, MenuArrowRight, MenuArrowUp } from "../icons"
import { sharedColors } from "../theme/colors"
import SubNavLinks from "./SubNavLinks"

const StyledLink = styled("div", {
  shouldForwardProp: (prop) => !["selected", "color", "isLast"].includes(prop),
})<{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  position: relative;
  font-size: 18px;
  width: 100%;
  line-height: 24px;
  white-space: nowrap;
  color: ${(props) => props.color};

  text-decoration: none;
  p {
    font-weight: ${(props) => (props.selected ? "600" : "400")};
    text-decoration: none;
  }
  padding: 16px;
  border-bottom: ${(props) => (props.isLast ? "none" : " 1px solid #F7F7F7")};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0;
    display: flex;
    align-items: center;
    transition: 300ms all ease;
    text-decoration: none;
    height: 100%;
    border-bottom: none;
    path {
      fill: ${(props) => (props.selected ? sharedColors.oldBrandBlue : "#000000")};
    }
    box-shadow: ${(props) => (props.selected ? "inset 0 -3px 0px 0px #2768A3" : "none")};
    :hover {
      p {
        font-weight: 500;
      }
      box-shadow: inset 0 -3px 0px 0px #2768a3;
    }
  }
`

const StyledMenuItem = styled("div", {
  shouldForwardProp: (prop) => !["active"].includes(prop),
})<{ active?: boolean }>`
  a {
    text-decoration: none;
    color: #000;
    display: block;
  }
  white-space: nowrap;
  p {
    color: ${(props) => (props.active ? sharedColors.oldBrandBlue : "inherit")};
    font-weight: ${(props) => (props.active ? 600 : "normal")};
  }
  padding: 13px 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      color: ${(props) => (props.active ? sharedColors.oldBrandBlue : "inherit")};
      font-weight: ${(props) => (props.active ? 600 : "normal")};
      path {
        fill: ${(props) => (props.active ? sharedColors.oldBrandBlue : "black")};
      }
      :hover {
        color: ${sharedColors.oldBrandBlue};
        path {
          fill: ${sharedColors.oldBrandBlue};
        }
      }
    }
  }
`

const StyledDiv = styled("div", {
  shouldForwardProp: (prop) => !["selected", "color", "isLast"].includes(prop),
})<{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  white-space: nowrap;

  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  text-decoration: none;
  p {
    font-weight: ${(props) => (props.selected ? "500" : "400")};
    color: ${(props) => (props.selected ? "#2768A3" : "#000000")};
    text-decoration: none;
  }
  path {
    fill: ${(props) => (props.selected ? "#2768A3" : "#000000")};
  }
  button {
    text-decoration: none;
    padding: 0;
    :hover {
      background: transparent;
      text-decoration: none;
    }
  }
  border-bottom: ${(props) => (props.isLast ? "none" : " 1px solid #F7F7F7")};
  ${(props) => props.theme.breakpoints.up("md")} {
    transition: 300ms all ease;
    border-bottom: none;
    box-shadow: ${(props) => (props.selected ? "inset 0 -3px 0px 0px #2768A3;" : "none")};
    :hover {
      p {
        font-weight: 500;
      }
      box-shadow: inset 0 -3px 0px 0px #2768a3;
    }
  }
`

const StyledHash = styled("div", {
  shouldForwardProp: (prop) => !["selected", "color", "isLast"].includes(prop),
})<{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};

  text-decoration: none;
  padding: 10px 0px;
  p {
    font-weight: ${(props) => (props.selected ? "500" : "400")};
  }
  border-bottom: ${(props) => (props.isLast ? "none" : " 1px solid #F7F7F7")};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px 24px;
    border-bottom: none;
  }
`

const StyledMobileButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => !["selected", "color", "isLast", "isOpen"].includes(prop),
})<{
  selected?: boolean
  color: string
  isLast?: boolean
  isOpen?: boolean
}>`
  font-size: 18px;
  line-height: 24px;

  text-decoration: none;
  padding: 16px;

  p {
    font-weight: ${(props) => (props.selected ? "600" : "400")};
    color: ${(props) => (props.selected ? "#2768A3" : "#000000")};
  }
  path {
    fill: ${(props) => (props.selected ? "#2768A3" : "#000000")};
  }
  border-bottom: ${(props) => (props.isLast ? "none" : " 1px solid #F7F7F7")};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px 24px;
    border-bottom: none;
  }
  &:focus-visible {
    border: 2px dashed white;
    outline-offset: 2px;
    outline: 3px dashed #10059f;
  }
  background-color: ${(props) => (props.isOpen ? "#F7F7F7" : "transparent")} !important;
`

const StyledButton = styled(Button)`
  display: flex;
  gap: 6px;
  align-items: center;
`

type Props = {
  href: string
  selected: boolean
  title: string
  color?: string
  onClick?: () => void
  isLast?: boolean
  subCategories?: any[]
  isOpen?: boolean
  // eslint-disable-next-line no-unused-vars
  setOpen?: (open: boolean) => void
  setSubMenuLink?: any
}

function NavLink({
  href,
  selected,
  title,
  color = sharedColors.black,
  onClick,
  isLast,
  subCategories,
  isOpen: isOpen,
  setOpen: setOpen,
  setSubMenuLink,
}: Props) {
  const isDesktop = useIsDesktop()
  const locale = useLocale()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [subSubAnchorEl, setSubSubAnchorEl] = useState(null)
  const handleSubSubClick = (event: any) => {
    setSubSubAnchorEl(event?.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setSubSubAnchorEl(null)
    setOpen?.(false)
  }

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    mainMenuClick(title, "")

    setAnchorEl(event.currentTarget.parentElement)
    setOpen?.(!isOpen)
  }

  const handleMobileClick = () => {
    mainMenuClick(title, "")
    setOpen?.(!isOpen)
  }

  const pathname = usePathname()
  if (href && href.includes("#") && (pathname === "/" || pathname === "/en"))
    return (
      <Link href={href} target={href?.includes(".") ? "_blank" : "_self"}>
        <StyledHash onClick={onClick} color={color} isLast={isLast}>
          <Mobile>
            <Text variant="LargeText">{title}</Text>
          </Mobile>
          <Desktop>
            <Text variant="MediumText">{title}</Text>
          </Desktop>
        </StyledHash>
      </Link>
    )
  if (subCategories?.length) {
    return (
      <>
        <Mobile>
          <List
            sx={{ width: "100%", padding: 0, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <StyledMobileButton
              selected={selected}
              color={color}
              id="basic-button"
              isOpen={isOpen}
              aria-controls={isOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : undefined}
              onClick={handleMobileClick}
            >
              <ListItemText
                primary={
                  <NewText
                    component="p"
                    desktopFontSize={16}
                    desktopLineHeight={"20px"}
                    mobileFontSize={14}
                    mobileLineHeight={"100%"}
                    textTransform="uppercase"
                    color={sharedColors.black}
                  >
                    {title}
                  </NewText>
                }
              />
              {!isOpen ? <MenuArrowUp fill="#1C1B1F" /> : <MenuArrowDown fill="#1C1B1F" />}
            </StyledMobileButton>
            <Collapse
              in={isOpen}
              timeout="auto"
              unmountOnExit
              style={{
                backgroundColor: isOpen ? "#F7F7F7" : "transparent",
                paddingRight: "16px",
              }}
            >
              <List component="div" disablePadding style={{ borderRight: "solid 1px #2768A352" }}>
                {subCategories.map((item, index) => {
                  const { title: titleName, url } = item
                    ? extractMenuProperties(item)
                    : { title: "", url: "/" }
                  if (!titleName) return null
                  const activeSubItem = decodeURI(pathname) + "/" === getNonUmbracoUrl(url)

                  const activeChildItem = item?.children?.some((el: any) => {
                    return (
                      decodeURI(pathname) + "/" === getNonUmbracoUrl(extractMenuProperties(el).url)
                    )
                  })
                  return (
                    <React.Fragment key={index.toString()}>
                      {item?.children?.length ? (
                        <SubNavLinks
                          closeMenu={onClick}
                          title={title}
                          data={item?.children}
                          pathname={pathname}
                        />
                      ) : (
                        <StyledMenuItem
                          key={index.toString()}
                          active={activeSubItem || activeChildItem}
                        >
                          <Link
                            onClick={() => {
                              setOpen?.(false)
                              mainMenuClick(title, titleName)
                            }}
                            href={getNonUmbracoUrl(url)}
                            target={url?.includes(".") ? "_blank" : "_self"}
                          >
                            <ListItemButton
                              onClick={onClick}
                              sx={{ padding: 0, pl: 1.5 }}
                              tabIndex={-1}
                            >
                              <ListItemText
                                primary={
                                  <NewText
                                    component="p"
                                    desktopFontSize={16}
                                    desktopLineHeight={"14px"}
                                    mobileFontSize={14}
                                    color={sharedColors.black}
                                    textTransform="uppercase"
                                  >
                                    {titleName}
                                  </NewText>
                                }
                              />
                            </ListItemButton>
                          </Link>
                        </StyledMenuItem>
                      )}
                    </React.Fragment>
                  )
                })}
              </List>
            </Collapse>
          </List>
        </Mobile>
        <Desktop>
          <StyledDiv color={color} isLast={isLast} selected={open || selected}>
            <StyledButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Mobile>
                <Text variant="LargeText">{title}</Text>
              </Mobile>
              <Desktop>
                <NewText
                  component={"p"}
                  desktopFontSize={16}
                  desktopLineHeight={"23px"}
                  desktopFontWeight={400}
                  color={sharedColors.black}
                >
                  {title}
                </NewText>
              </Desktop>

              {!isOpen ? <MenuArrowUp fill="#1C1B1F" /> : <MenuArrowDown fill="#1C1B1F" />}
            </StyledButton>
            {anchorEl && (
              <Menu
                disableScrollLock={true}
                id="basic-menu"
                anchorEl={anchorEl}
                open={!!isOpen && isDesktop}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: locale === "he" ? "right" : "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: locale === "he" ? "right" : "left",
                }}
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                  },
                  ".MuiPaper-root": {
                    // Target the root paper element of the menu
                    borderRadius: " 0 0 8px 8px", // Set your desired border radius here
                    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
                    // Add any other styling you need here
                  },
                  zIndex: 1000001,
                }}
              >
                {subCategories.map((item, index) => {
                  const { title: titleName, url } = extractMenuProperties(item)

                  if (!titleName) return null
                  const activeSubItem = decodeURI(pathname) + "/" === getNonUmbracoUrl(url)

                  const activeChildItem = item?.children?.some((el: any) => {
                    return (
                      decodeURI(pathname) + "/" === getNonUmbracoUrl(extractMenuProperties(el).url)
                    )
                  })
                  const openSubItem =
                    Boolean(subSubAnchorEl) &&
                    isDesktop &&
                    index ===
                      Number(
                        (subSubAnchorEl as unknown as HTMLDivElement)?.getAttribute("data-index"),
                      )

                  return item?.children?.length ? (
                    <React.Fragment key={index.toString()}>
                      <StyledMenuItem
                        key={index.toString()}
                        active={activeSubItem || activeChildItem || openSubItem}
                      >
                        <MenuItem disableRipple onClick={handleSubSubClick} data-index={index}>
                          {titleName}
                          {locale === "en" ? (
                            <MenuArrowRight
                              fill={"#2768A3"}
                              style={{ transform: "rotate(180deg)" }}
                            />
                          ) : (
                            <MenuArrowRight fill={"#2768A3"} />
                          )}
                        </MenuItem>
                      </StyledMenuItem>
                      {item?.children?.length ? (
                        <Menu
                          anchorEl={subSubAnchorEl}
                          open={openSubItem}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: locale === "en" ? "right" : "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: locale === "en" ? "left" : "right",
                          }}
                          sx={{
                            ".MuiPaper-root": {
                              // Target the root paper element of the menu
                              borderRadius: "8px", // Set your desired border radius here
                              minWidth: "180px",
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
                              // Add any other styling you need here
                            },
                          }}
                        >
                          {item?.children?.map((it: any, ind: any) => {
                            const { title, url } = extractMenuProperties(it)

                            const activeSubItem =
                              decodeURI(pathname) + "/" === getNonUmbracoUrl(url)
                            return (
                              <StyledMenuItem key={ind.toString()} active={activeSubItem}>
                                <Link
                                  href={getNonUmbracoUrl(url)}
                                  target={url?.includes(".") ? "_blank" : "_self"}
                                >
                                  <MenuItem
                                    disableRipple
                                    onClick={() => {
                                      handleClose()
                                    }}
                                  >
                                    {title}
                                  </MenuItem>
                                </Link>
                              </StyledMenuItem>
                            )
                          })}
                        </Menu>
                      ) : null}
                    </React.Fragment>
                  ) : (
                    <StyledMenuItem key={index.toString()} tabIndex={0} active={activeSubItem}>
                      <Link
                        target={url?.includes(".") ? "_blank" : "_self"}
                        href={getNonUmbracoUrl(url)}
                        onClick={() => {
                          handleClose()
                          mainMenuClick(title, titleName)
                          setSubMenuLink(title)
                        }}
                      >
                        <MenuItem sx={{ textTransform: "uppercase" }} disableRipple>
                          {titleName}
                        </MenuItem>
                      </Link>
                    </StyledMenuItem>
                  )
                })}
              </Menu>
            )}
          </StyledDiv>
        </Desktop>
      </>
    )
  }

  return (
    <Link
      href={href || ""}
      target={href?.includes(".") ? "_blank" : "_self"}
      style={{
        textDecoration: "none",
      }}
    >
      <StyledLink onClick={onClick} selected={selected} color={color} isLast={isLast}>
        <NewText
          component="p"
          desktopFontSize={16}
          desktopLineHeight={"23px"}
          mobileFontSize={14}
          mobileLineHeight={"100%"}
          desktopFontWeight={selected ? 600 : 500}
          color={selected ? sharedColors.oldBrandBlue : sharedColors.black}
          textTransform="uppercase"
        >
          {title}
        </NewText>
      </StyledLink>
    </Link>
  )
}

export default NavLink
