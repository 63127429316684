"use client"
import styled from "@emotion/styled"
import "swiper/css"
import { extractImage, extractProperty } from "utils"
import { Container, NewText } from "../"
import UseImageAccordion, { AccordionItem } from "./UseImageAccordion"

const StyledDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 32px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 40px;
  }
`
const TextBox = styled.div`
  text-align: center;
`
type Props = {
  data: any
  locale: string
}

export const NewActiveArms: React.FC<Props> = ({ data }) => {
  const title = extractProperty(data, "title")
  const text = extractProperty(data, "text")
  const arms = extractProperty(data, "arms")
  const armsList = arms.map((prop: any, i: number) => {
    return {
      id: i,
      logo: extractImage(prop, "logo"),
      transparentLogo: extractProperty(prop, "transparentLogo"),
      image: extractImage(prop, "image"),
      imageMobile: extractImage(prop, "imageMobile"),
      title: extractProperty(prop, "title"),
      content: extractProperty(prop, "content"),
      link: extractProperty(prop, "link")?.[0]?.url,
    }
  }) as AccordionItem[]
  return (
    <Container>
      <TextBox>
        <NewText
          desktopFontSize={40}
          desktopFontWeight="400"
          desktopLineHeight="110%"
          mobileFontSize={32}
          mobileFontWeight="400"
          mobileLineHeight={"112.5%"}
          component="h2"
          color={"black"}
        >
          {title}
        </NewText>

        <StyledDescriptionContainer>
          <NewText
            desktopFontSize={18}
            desktopFontWeight="400"
            desktopLineHeight="26px"
            mobileFontSize={16}
            mobileFontWeight="400"
            mobileLineHeight={"23px"}
            component="div"
            color={"black"}
            dangerouslySetInnerHTML={{ __html: text }}
            maxWidth={"800px"}
          />
        </StyledDescriptionContainer>
      </TextBox>
      <UseImageAccordion accordionData={armsList} height="420px" />
    </Container>
  )
}
export default NewActiveArms
