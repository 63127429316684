"use client"
import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import { extractProperty } from "utils"
import NewText from "../NewText/NewText"
import { sharedColors } from "../theme/colors"
import { useTransitionUpStyles } from "../transitions/useTransitionUpStyles"
import { delayTime, styleObjectToCss, useInViewConfig } from "../transitions/utils"
import LinkButton from "./LinkButton"

type Props = {
  data: any
}

const StyledDiv = styled.div`
  margin-top: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
    p {
      max-width: 675px;
    }
  }
`

export const TitleTextLink = ({ data }: Props) => {
  const title = extractProperty(data, "title")
  const content = extractProperty(data, "content")
  const link = extractProperty(data, "link")
  const { ref, inView } = useInView(useInViewConfig)
  const titleTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)
  const LinkTransitionStyles = useTransitionUpStyles(inView, delayTime * 1.5)

  return (
    <StyledDiv ref={ref}>
      <NewText
        component="h1"
        desktopFontSize={40}
        desktopLineHeight="44px"
        mobileFontSize={32}
        mobileLineHeight="36px"
        color={sharedColors.black}
        cssString={styleObjectToCss(titleTransitionStyles)}
      >
        {title}
      </NewText>
      <NewText
        component="div"
        desktopFontSize={18}
        desktopLineHeight="25.92px"
        mobileFontSize={16}
        mobileLineHeight="23px"
        dangerouslySetInnerHTML={{ __html: content }}
        color={sharedColors.black}
        marginTop={"16px"}
        cssString={styleObjectToCss(descriptionTransitionStyles)}
      />
      <LinkButton
        link={link}
        cssString={styleObjectToCss(LinkTransitionStyles)}
        mainTitle={title}
      />
    </StyledDiv>
  )
}

export default TitleTextLink
