"use client"
import styled from "@emotion/styled"
import Link from "next/link"
import NewText from "../NewText/NewText"
import { sharedColors } from "../theme/colors"

const StyledTable = styled.div`
  border: 1px solid #6f6f6f;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 70%;
  }
`

const StyledItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledLine = styled.div`
  height: 1px;
  background-color: #6f6f6f;
  width: 100%;
`

const StyledBottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledLink = styled(Link)`
  width: 153px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${sharedColors.black};
  border-radius: 200px;
  text-decoration: none;
`

type Props = {
  data: any
  text?: string
  link?: string
}

function TableChart({ data, text, link }: Props) {
  return (
    <StyledTable>
      {data.map((item: any, index: any) => (
        <StyledItemDiv key={index}>
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight={"17px"}
            color={sharedColors.black}
          >
            {item.title}
          </NewText>
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopLineHeight={"20px"}
            desktopFontWeight={400}
            mobileFontSize={12}
            mobileLineHeight={"17px"}
            color={sharedColors.black}
          >
            {item.value}
          </NewText>
        </StyledItemDiv>
      ))}
      {(text || link) && <StyledLine />}
      {(text || link) && (
        <StyledBottomDiv>
          {text && (
            <NewText
              component={"p"}
              desktopFontSize={14}
              desktopLineHeight={"20px"}
              desktopFontWeight={400}
              mobileFontSize={12}
              mobileLineHeight={"18px"}
              color={sharedColors.black}
            >
              {text}
            </NewText>
          )}
          {link && (
            <StyledLink href={link}>
              <NewText
                component={"p"}
                desktopFontSize={16}
                desktopLineHeight={"20px"}
                desktopFontWeight={600}
                color={sharedColors.black}
              >
                לאתר הבורסה
              </NewText>
            </StyledLink>
          )}
        </StyledBottomDiv>
      )}
    </StyledTable>
  )
}

export default TableChart
