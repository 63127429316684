"use client"
import styled from "@emotion/styled"
import NewText from "../NewText/NewText"

import Link from "next/link"
import { buttonClick, extractProperty, getUmbracoPDFUrl } from "utils"
import { sharedColors } from "../theme/colors"
import FileIcon2 from "./FileIcon2"

const StyledWrapper = styled.div`
  box-shadow: 0px 4px 12px 0px #00000029;
  border-radius: 16px;
  min-height: 70px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: row;
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    min-height: 160px;
  }
`

const StyledLink = styled(Link)`
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-decoration: none;
  border-bottom: 1px solid black;
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-left: 5px;
  }
`

type Props = {
  data: any
  view: string
  mainTitle: string
}

function ReportsWrapperSmall({ data, mainTitle }: Props) {
  const links = extractProperty(data, "reportsPicker")
  const reportFile = extractProperty(data, "reportFile")

  return (
    <StyledWrapper>
      <FileIcon2 width={32} height={32} fill={sharedColors.oldBrandBlue} />
      <StyledLink
        href={getUmbracoPDFUrl(links?.[0]?.url) || getUmbracoPDFUrl(reportFile?.[0]?.url) || ""}
        target="_blank"
        onClick={() => buttonClick(links[0]?.name, mainTitle)}
      >
        <NewText
          component={"p"}
          desktopFontSize={14}
          desktopLineHeight={"20px"}
          desktopFontWeight={400}
          mobileFontSize={12}
          mobileLineHeight={"17px"}
          color={sharedColors.black}
        >
          {links[0]?.name || reportFile?.[0]?.name}
        </NewText>
      </StyledLink>
    </StyledWrapper>
  )
}

export default ReportsWrapperSmall
