"use client"
import styled from "@emotion/styled"
import { ElementType } from "react"
import NewText from "./NewText/NewText"
import { sharedColors } from "./theme/colors"

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: center;
  }
`

const StyledIndicator = styled.div`
  width: 3px;
  height: 32px;
  background-color: ${(props) => props.color};
  margin-right: 16px;
`
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    padding: 0px;
    margin: 0px;
  }
`

type Props = {
  title: string
  titleColor?: string
  accentColor?: string
  accent?: boolean
  hideIndicator?: boolean
  subtitle?: string
  component?: ElementType<any>
}

const SectionTitle: React.FC<Props> = ({
  title,
  titleColor = sharedColors.black,
  accentColor,
  accent = true,
  hideIndicator = false,
  subtitle,
  component = "h1",
}) => {
  return (
    <StyledContainer>
      <StyledTitleContainer>
        {title && accent && !hideIndicator && <StyledIndicator color={accentColor} />}
        <NewText
          desktopFontSize={40}
          desktopFontWeight="400"
          desktopLineHeight="44px"
          mobileFontSize={32}
          mobileFontWeight="400"
          mobileLineHeight={"36px"}
          component={component}
          color={titleColor}
        >
          {title}
        </NewText>
      </StyledTitleContainer>
      {subtitle && (
        <NewText
          desktopFontSize={18}
          desktopFontWeight="400"
          desktopLineHeight="26px"
          mobileFontSize={16}
          mobileFontWeight="400"
          mobileLineHeight={"23px"}
          component="div"
          color={sharedColors.black}
          dangerouslySetInnerHTML={{
            __html: `<div style="max-width: 570px;">${subtitle}</div>`,
          }}
        />
      )}
    </StyledContainer>
  )
}

export default SectionTitle
