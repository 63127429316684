"use client"

import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import dynamic from "next/dynamic"
import { useEffect } from "react"
import { extractProperty } from "utils"
import NewText from "./NewText/NewText"
import { useIsDesktop } from "./common/bit/MediaQueries"
import { sharedColors } from "./theme/colors"
const Chart = dynamic(() => import("react-apexcharts"), { ssr: false })

type Props = {
  data: any
  tooltipRef?: any
}

const StyledDiv = styled.div`
  text {
    direction: rtl;
  }
`

const StyledBottomDiv = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`

const StyledDot = styled.div<{ $background: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: ${(p) => p.$background};
`

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

function TzabarLineChart({ data, tooltipRef }: Props) {
  const locale = useLocale()
  const isDesktop = useIsDesktop()
  const operational = data.map((item: any) => extractProperty(item, "operational"))

  const constructionPreConstruction = data.map((item: any) =>
    extractProperty(item, "constructionPreConstruction"),
  )
  const developmentEarlyDevelopment = data.map((item: any) =>
    extractProperty(item, "developmentEarlyDevelopment"),
  )

  const country = data.map((item: any) => extractProperty(item, "country"))
  const color = data.map((item: any) => "#" + extractProperty(item, "color"))

  useEffect(() => {
    tooltipRef && tooltipRef.current && (tooltipRef.current.style.opacity = 0)
  }, [tooltipRef])
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },

      zoom: {
        enabled: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,

        columnWidth: "40px",

        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "18px",
              fontWeight: 400,
            },
          },
        },
      },
    },

    xaxis: {
      categories:
        locale === "he"
          ? isDesktop
            ? ["בהפעלה", ["בהקמה"], ["בייזום ופיתוח"]]
            : [["בייזום ופיתוח"], ["בהקמה"], "בהפעלה"]
          : ["Operational", ["Under Construction"], ["Initiation & Development"]],

      labels: {
        offsetY: 4, // Adjust the vertical position of x-axis labels
        style: {
          fontSize: isDesktop ? "18px" : "12px", // Set the font size for x-axis labels
          fontWeight: 400,
        },
        rotate: 0,
      },
    },
    yaxis: {
      labels: {
        // offsetX: -30, // Adjust the horizontal position of y-axis labels
        style: {
          fontSize: "18px", // Set the font size for x-axis labels
          fontWeight: 400,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    colors: color,
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  } as any

  const series = country.map((country: any, index: number) => ({
    name: country,
    data: [
      operational[index] ? operational[index] : 0,
      constructionPreConstruction[index] ? constructionPreConstruction[index] : 0,
      developmentEarlyDevelopment[index] ? developmentEarlyDevelopment[index] : 0,
    ],
  }))

  return (
    <StyledDiv>
      <Chart options={options} type="bar" width="100%" height="470" series={series} />
      <StyledBottomDiv>
        {country.map((item: any, index: number) => (
          <StyledTextWrapper key={index}>
            <StyledDot $background={color[index]} />
            <NewText
              component={"p"}
              desktopFontSize={16}
              desktopFontWeight={400}
              desktopLineHeight={"23px"}
              mobileFontSize={14}
              mobileLineHeight={"20px"}
              color={sharedColors.black}
            >
              {item}
            </NewText>
          </StyledTextWrapper>
        ))}
      </StyledBottomDiv>
    </StyledDiv>
  )
}

export default TzabarLineChart
