"use client"

import styled from "@emotion/styled"

import Image from "next/image"
import { useRef, useState } from "react"
import { extractProperty, getUmbracoMediaUrl } from "utils"
import NewText from "./NewText/NewText"
import { Desktop, Mobile } from "./common/bit/MediaQueries"
import Pause from "./icons/video/Pause"
import Play from "./icons/video/Play"
import { sharedColors } from "./theme/colors"

type Props = {
  data: any
}

const StyledWrapper = styled.div`
  height: 1100px;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  > div {
    flex-shrink: 0;

    &:first-of-type {
      flex-shrink: 1;
      flex-grow: 1;
    }
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 750px;
  }
`

const StyledImagesContainer = styled.div<{ $paddingTop?: boolean }>`
  padding-top: ${(p) => (p.$paddingTop ? `58px` : "0px")};
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const StyledVideoContainer = styled.div<{ $videoHeight?: string }>`
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000917;
  height: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 100%;

    overflow: hidden;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    > div {
      width: 100%;
    }
  }
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    inset: 0;
  }
`

const StyledContainer = styled.div`
  inset: 0;
  padding: 24px;
  z-index: 10;
  align-self: center;
  margin-top: -90px;
  width: calc(100vw - 32px);
  height: fit-content;
  border-radius: 16px;
  text-align: center;
  box-shadow: 0px 4px 16px 0px #00000029;

  background-color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 470px;
    width: 848px;
    background-image: url("/images/transparentTriangleBg.png");
    background-repeat: no-repeat;
    background-size: 24%;
    background-position-y: top;
    background-position-x: -22px;
  }
`
const StyledButtonsWrapper = styled.div`
  z-index: 999;
  direction: rtl;
  display: flex;
  position: absolute;
  inset-inline-start: 0;
  bottom: 20px;
  right: 8px;
  button {
    padding: 0;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:focus-visible {
      border: 2px dashed white;
      outline-offset: 2px;
      outline: 3px dashed #10059f;
    }
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    bottom: 0px;
    inset-inline-start: 8px;
    top: 32px;
    bottom: unset;
  }
`

const StyledTextWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down("sm")} {
    h1 {
      max-width: 280px;
    }
  }
`

const StyledTabsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 0 auto;
    margin-top: 24px;
    gap: 40px;
    width: fit-content;
    flex-direction: row;
  }
`

const StyledInnerTab = styled.div`
  display: flex;
  flex-direction: column;
`

function EnergyHeader({ data }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(true)
  const backgroundVideoDesktop = extractProperty(data, "backgroundVideoDesktop")
  const backgroundVideoMobile = extractProperty(data, "backgroundVideoMobile")
  const mainTitle = extractProperty(data, "mainTitle")
  const subTitle = extractProperty(data, "subTitle")
  const totalShare = extractProperty(data, "totalShare")
  const totalValue = extractProperty(data, "totalValue")
  const renewableEnergyItems = extractProperty(data, "renewableEnergyItesm")?.map(
    (item: any) => item.properties,
  )
  const selectColor4 = extractProperty(data, "selectColor4")
  const videoMobileRef = useRef<HTMLVideoElement>(null)
  const handleVideo = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
      } else {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
    if (videoMobileRef.current) {
      if (isPlaying) {
        videoMobileRef.current.pause()
        setIsPlaying(false)
      } else {
        videoMobileRef.current.play()
        setIsPlaying(true)
      }
    }
  }

  return (
    <StyledWrapper>
      <StyledImagesContainer>
        <StyledVideoContainer>
          <Desktop>
            <StyledVideo autoPlay loop muted={true} playsInline ref={videoRef}>
              <source src={getUmbracoMediaUrl(backgroundVideoDesktop)} type="video/mp4" />
            </StyledVideo>
          </Desktop>
          <Mobile>
            <StyledVideo autoPlay loop muted={true} playsInline ref={videoMobileRef}>
              <source src={getUmbracoMediaUrl(backgroundVideoMobile)} type="video/mp4" />
            </StyledVideo>
          </Mobile>
          <StyledButtonsWrapper>
            <button onClick={handleVideo} aria-label={isPlaying ? "עצירת הוידאו" : "ניגון הוידאו"}>
              {isPlaying ? <Pause /> : <Play />}
            </button>
          </StyledButtonsWrapper>
        </StyledVideoContainer>
      </StyledImagesContainer>
      <StyledContainer>
        <StyledTextWrapper>
          <NewText
            component={"h1"}
            desktopFontSize={48}
            desktopLineHeight={"1.25"}
            desktopFontWeight={600}
            mobileFontSize={40}
            mobileLineHeight={"1.15"}
            color={sharedColors.black}
            margin={"8px auto 0"}
          >
            {mainTitle}
          </NewText>
          <NewText
            component={"h2"}
            desktopFontSize={18}
            desktopLineHeight={"1.44"}
            desktopFontWeight={400}
            mobileFontSize={16}
            color={sharedColors.black}
            maxWidth={540}
            margin={"8px auto 0"}
          >
            {subTitle}
          </NewText>
          <StyledTabsDiv>
            <Mobile>
              <StyledInnerTab style={{ margin: "0 auto" }}>
                <NewText
                  component={"h3"}
                  desktopFontSize={14}
                  desktopLineHeight={"1.44"}
                  desktopFontWeight={700}
                  color={sharedColors.black}
                  mobileFontSize={16}
                >
                  {totalShare}
                </NewText>
                <NewText
                  component={"p"}
                  desktopFontSize={32}
                  desktopLineHeight={"1.125"}
                  desktopFontWeight={900}
                  mobileLineHeight={"1.44"}
                  mobileFontSize={32}
                  color={`#${selectColor4}`}
                  marginTop={"4px"}
                >
                  {totalValue}
                </NewText>
              </StyledInnerTab>
            </Mobile>
            {renewableEnergyItems?.map((item: any, i: number) => {
              const color = item?.filter((prop: any) => prop.propertyName === "selectColor1")[0]
                ?.propertyValue?.value
              return (
                <StyledInnerTab key={i}>
                  {item?.map((block: any, i: number) => {
                    if (block?.propertyName === "title") {
                      return (
                        <NewText
                          component={"h3"}
                          desktopFontSize={14}
                          desktopLineHeight={"1.44"}
                          desktopFontWeight={400}
                          color={sharedColors.black}
                          mobileFontSize={16}
                          key={i}
                        >
                          {block.propertyValue?.value}
                        </NewText>
                      )
                    } else if (block?.propertyName === "itemValue") {
                      return (
                        <NewText
                          component={"p"}
                          desktopFontSize={32}
                          desktopLineHeight={"1.125"}
                          desktopFontWeight={400}
                          mobileLineHeight={"1.44"}
                          mobileFontSize={32}
                          color={`#${color}`}
                          marginTop={"4px"}
                          key={i}
                        >
                          {block.propertyValue?.value}
                        </NewText>
                      )
                    } else return null
                  })}
                </StyledInnerTab>
              )
            })}
            <Desktop>
              <StyledInnerTab>
                <NewText
                  component={"h3"}
                  desktopFontSize={14}
                  desktopLineHeight={"1.44"}
                  desktopFontWeight={700}
                  color={sharedColors.black}
                >
                  {totalShare}
                </NewText>
                <NewText
                  component={"p"}
                  desktopFontSize={32}
                  desktopLineHeight={"1.125"}
                  desktopFontWeight={900}
                  mobileLineHeight={"1.44"}
                  mobileFontSize={32}
                  color={`#${selectColor4}`}
                  marginTop={"4px"}
                >
                  {totalValue}
                </NewText>
              </StyledInnerTab>
            </Desktop>
          </StyledTabsDiv>
        </StyledTextWrapper>
      </StyledContainer>

      <Mobile>
        <Image
          src={"/images/transparentTriangleBg.png"}
          width={170}
          height={237}
          alt=""
          style={{
            position: "absolute",
            left: 0,
            top: "214%",
            objectFit: "cover",
          }}
        />
      </Mobile>
    </StyledWrapper>
  )
}

export default EnergyHeader
