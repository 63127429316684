"use client"
import styled from "@emotion/styled"
import Image from "next/image"
import { getUmbracoMediaUrl } from "utils"
import ButtonWithArrow from "../ButtonWithArrow"
import NewText from "../NewText/NewText"
import { Desktop, Mobile } from "../common/bit/MediaQueries"
import { XIcon } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledActionDiv = styled.div<{ $height?: number; $width?: number }>`
  height: ${(p) => (p.$height ? `${p.$height}px` : "auto")};
  width: ${(p) => (p.$width ? `${p.$width}px` : "auto")};
  border-radius: 20px 20px 28px 28px;
  box-shadow: 0px 16px 32px 0px #11182733;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px 30px 0px;
  position: relative;
  background: ${sharedColors.white};
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: 50px;
  }
`

const StyledImageWrapper = styled.div`
  position: relative;
  height: 430px;
  width: 100%;
  border-bottom: 1px solid rgba(21, 62, 105, 0.3);
  border-opacity: 0.3;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 430px;
    width: 100%;
  }
`

const StyledSvgWrapper = styled.div`
  position: absolute;
  top: 0px;
  z-index: 1;
  top: 12px;
  right: 11px;
  cursor: pointer;
`

type ActionStateDesktopProps = {
  title: string
  image: string
  buttonText: string
  buttonAction?: () => void
  height?: number
}

function ActionStateDesktop({
  title,
  image,
  buttonText,
  height,
  buttonAction,
}: ActionStateDesktopProps) {
  return (
    <StyledActionDiv $height={height}>
      <StyledSvgWrapper>
        <XIcon />
      </StyledSvgWrapper>
      <NewText
        component={"p"}
        desktopFontSize={18}
        desktopFontWeight={500}
        desktopLineHeight={"26px"}
      >
        {title}
      </NewText>
      <StyledImageWrapper>
        <Desktop>
          <Image src={getUmbracoMediaUrl(image)} alt="image" height={260} width={340} />
        </Desktop>
        <Mobile>
          {" "}
          <Image src={getUmbracoMediaUrl(image)} alt="image" fill />
        </Mobile>
      </StyledImageWrapper>
      <ButtonWithArrow
        title={buttonText}
        onClick={buttonAction && buttonAction}
        reverse={true}
        justifyContent="center"
      />
    </StyledActionDiv>
  )
}
export default ActionStateDesktop
