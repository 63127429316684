import { SVGProps } from "react"
const RadioCheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx="12" cy="12" r="11.5" stroke="black" />
    <circle cx="12" cy="12" r="6" fill="black" />
  </svg>
)
export default RadioCheckedIcon
