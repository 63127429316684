"use client"
import styled from "@emotion/styled"
import { useState } from "react"
import { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import { Swiper, SwiperSlide } from "swiper/react"
import { extractImage, extractProperty } from "utils"
import ImageContainer from "../ImageContainer"
import ImageWithAlt from "../ImageWithAlt"
import NewText from "../NewText/NewText"
import TitleWithLine from "../TitleWithLine/TitleWithLine"
import { sharedColors } from "../theme/colors"

const StyledTitleContainer = styled.div`
  margin: 0 20px 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 0 0 26px 52px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    margin: 0 0 40px 52px;
  }
`

const StyledSlide = styled.div`
  height: 325px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: calc((100vw - 40px) / 2);
  }
`

const StyledActionsContainer = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 80px;
    gap: 47px;
    height: 49px;
  }
`

const StyledArrowsContainer = styled.div`
  display: flex;
  gap: 16px;
`
const StyledProgressBarContainer = styled.div`
  height: 1px;
  background: ${sharedColors.sliderBlue};
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 750px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 1000px;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    width: 1127px;
  }
`
const StyledNumbersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
const StyledArrowContainer = styled.div`
  cursor: pointer;
`
const StyledBar = styled.div<{ activeIndex: number; length: number }>`
  width: ${(props) => `calc((1000px / ${props.length} ) * ${props.activeIndex + 1}) ;`}
    ${(props) => props.theme.breakpoints.up("xl")} {
    width: ${(props) => `calc((1127px / ${props.length} ) * ${props.activeIndex + 1}) ;`};
  }

  height: 3px;
  position: relative;
  top: -1px;
  background: ${sharedColors.sliderBlue};
`
const StyledLine = styled.div`
  width: 45px;
  height: 2px;
  background: ${sharedColors.darkestBlue};
`

const StyledContainer = styled.div`
  margin-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 0px;
  }
`

type DealsCarouselProps = {
  data: any
}

export const DealsCarousel: React.FC<DealsCarouselProps> = ({ data }) => {
  const title = extractProperty(data, "carouselTitle")
  const deals = extractProperty(data, "deals")
  const [activeIndex, setActiveIndex] = useState<number>(0)
  return (
    <StyledContainer>
      <StyledTitleContainer>
        <TitleWithLine
          titleColor={sharedColors.black}
          title={title}
          titleFontSize={64}
          mobileTitleFontSize={36}
          marginTop={32}
        />
      </StyledTitleContainer>
      <Swiper
        navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
        key={1}
        style={{ padding: 0 }}
        modules={[Navigation]}
        breakpoints={{
          0: {
            slidesPerView: "auto",
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {deals.map((item: any, index: number) => {
          const url = extractImage(item, "dealPic")
          return (
            <SwiperSlide key={index.toString()}>
              <StyledSlide>
                <ImageWithAlt image={url} fill />
              </StyledSlide>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <StyledActionsContainer>
        <StyledNumbersContainer>
          <NewText
            component="p"
            desktopFontSize={18}
            desktopLineHeight={25.8}
            color={sharedColors.blue}
            smallDesktopFontSize={18}
            smallDesktopLineHeight="23px"
          >
            0{activeIndex + 1}
          </NewText>{" "}
          <StyledLine />{" "}
          <NewText
            component="p"
            desktopFontSize={18}
            desktopLineHeight={25.8}
            color={sharedColors.darkestBlue}
            smallDesktopFontSize={16}
            smallDesktopLineHeight="19px"
          >
            0{deals.length}
          </NewText>
        </StyledNumbersContainer>

        <StyledProgressBarContainer>
          <StyledBar activeIndex={activeIndex} length={deals.length} />
        </StyledProgressBarContainer>
        <StyledArrowsContainer>
          <StyledArrowContainer className={`arrow-left arrow`}>
            <ImageContainer
              height={24}
              desktopUrl={"/icons/Arrow/arrow-left.png"}
              width={24}
              alt="arrow-left"
              mediumHeight={16}
              mediumWidth={16}
            />
          </StyledArrowContainer>
          <StyledArrowContainer className={`arrow-right arrow`}>
            <ImageContainer
              height={24}
              desktopUrl={"/icons/Arrow/arrow-right.png"}
              width={24}
              alt="arrow-right"
              mediumHeight={16}
              mediumWidth={16}
            />
          </StyledArrowContainer>
        </StyledArrowsContainer>
      </StyledActionsContainer>
    </StyledContainer>
  )
}

export default DealsCarousel
