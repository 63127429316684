"use client"

import styled from "@emotion/styled"
import { Box } from "@mui/material"
import Image from "next/image"
import Link from "next/link"

import { LeftArrow, NewText, RightArrow, SquareIcon, UnitsIcon, transitionTime } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { transitionEase } from "ui/transitions/utils"
import { getUmbracoMediaUrl } from "utils"
import { actionButtonClick, projectSearch } from "utils/gtm"
import { AllProjects } from "utils/projects"
import blurred from "./blurred64"

const StyledCard = styled.div`
  display: flex;
  width: 100%;
  height: 460px;
  position: relative;
  padding: 8px 16px 8px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  overflow: hidden;
  background-size: cover;
`

const StyledBottomDiv = styled.div`
  position: absolute;
  bottom: 0;
  height: auto;
  min-height: 97px;
  background: white;
  opacity: 0.8;
  width: 100%;
  padding: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 103px;
  }
`

const StyledStatusDiv = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.88);
  border-radius: 40px;
  position: absolute;
  right: 8px;
  top: 8px;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  position: relative;
  z-index: 2;
`

const StyledTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Details = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-wrap: wrap;
`
const Place = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
`
const StyledBottom = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
  align-self: stretch;
`
const Border = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 16px;
  border-left: 1px solid #6f6f6f;
`

const StyledLink = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
`
const StyledLinkWrap = styled(Link)`
  img {
    transform: scale(1);
    transition: transform ${transitionTime}s ${transitionEase};
  }
  &:focus-visible,
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`

const UnitsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 8px;
  }
`

const StyledUnit = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

type Array = {
  key?: any
  id: number
  value: string
}[]

function ProjectCard({
  project,
  locale,
  statuses,
  subsidiaries,
  searchText,
  inView,
  designation,
  cities,
}: {
  project: AllProjects[number]
  locale: string
  toProject: string
  statuses: Array
  cities: Array
  countries: Array
  subsidiaries: Array
  searchText?: any
  inView?: boolean
  designation?: Array
}) {
  const applicableStatuses = project.projectStatuses
    .map((status) => {
      // Find the matching status in the statuses.options
      return statuses.find((option) => option.id === status.id)?.value
    })
    .filter(Boolean) // Filter out any undefined results from find
  const parent = project.parent
    .map((status) => {
      // Find the matching status in the statuses.options
      return subsidiaries.find((option) => option.id === status.id)?.value
    })
    .filter(Boolean) // Filter out any undefined results from find

  const projectCity = project.city
    .map((status) => {
      // Find the matching status in the statuses.options
      return cities.find((option) => option.id === status.id)?.value
    })
    .filter(Boolean)

  const parts = project.url!.split("/").filter((part) => part !== "")
  const lastPart = parts[parts.length - 1]

  const projectDes = project.propertyDesignations.map((status) => {
    // Find the matching status in the statuses.options
    return designation?.find((option) => option.id === status.id)?.value
  })

  const localePrefix = locale === "he" ? "" : "/en"
  const squareMeter = project?.squareMeter
  const residentialUnits = project?.residentialUnits

  return (
    <StyledLinkWrap
      key={project.url}
      onClick={() => {
        actionButtonClick(project.projectTitle || "", "עבר, הווה, עתיד: הפרויקטים שלנו")
        searchText && searchText.length > 0 && projectSearch(project.projectTitle, searchText)
      }}
      href={`${localePrefix}/projects/${lastPart}`}
      style={{ textDecoration: "none" }}
    >
      <StyledCard>
        <Image
          src={getUmbracoMediaUrl(project.thumbnail || "/")}
          fill
          alt={project.projectTitle || ""}
          style={{ zIndex: 0, objectFit: "cover" }}
          loading={inView ? "eager" : "lazy"}
          placeholder="blur"
          blurDataURL={blurred}
          sizes="(max-width: 600px) 100vw, 450px"
        />
        {applicableStatuses && applicableStatuses.length > 0 && (
          <StyledStatusDiv>
            <NewText
              component={"span"}
              desktopFontSize={16}
              desktopLineHeight={"24px"}
              mobileFontSize={14}
              mobileLineHeight={"20px"}
              color={colors.black}
              style={{ whiteSpace: "nowrap" }}
            >
              {applicableStatuses?.join(", ")}
            </NewText>
          </StyledStatusDiv>
        )}
        <StyledBottomDiv>
          <StyledContent>
            <StyledTop>
              <NewText
                component={"h3"}
                desktopFontSize={16}
                desktopFontWeight={600}
                color={colors.black}
                desktopLineHeight={"23px"}
                mobileFontSize={14}
                mobileLineHeight={"20px"}
              >
                {project.projectTitle}
              </NewText>
              <Details>
                <Place>
                  <NewText
                    component={"span"}
                    desktopFontSize={16}
                    desktopLineHeight={"24px"}
                    mobileFontSize={16}
                    mobileLineHeight={"20px"}
                    color={colors.black}
                  >
                    {parent ? parent : ""}{" "}
                  </NewText>
                </Place>
                {projectDes && projectDes.length > 0 && (
                  <>
                    <Border />
                    <NewText
                      component={"span"}
                      desktopFontSize={16}
                      desktopLineHeight={"24px"}
                      mobileFontSize={16}
                      mobileLineHeight={"20px"}
                      color={colors.black}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {projectDes?.join(", ")}
                    </NewText>
                  </>
                )}
                {projectCity && projectCity.length > 0 && (
                  <>
                    <Border />
                    <NewText
                      component={"span"}
                      desktopFontSize={16}
                      desktopLineHeight={"24px"}
                      mobileFontSize={16}
                      mobileLineHeight={"20px"}
                      color={colors.black}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {projectCity?.join(", ")}
                    </NewText>
                  </>
                )}
              </Details>
            </StyledTop>
            <StyledBottom>
              <StyledUnit>
                {!!squareMeter && (
                  <UnitsDiv>
                    <SquareIcon />
                    <NewText
                      component={"span"}
                      desktopFontSize={16}
                      desktopLineHeight={"24px"}
                      desktopFontWeight={600}
                      mobileFontSize={16}
                      mobileLineHeight={"20px"}
                      color={colors.black}
                    >
                      {squareMeter} {locale === "he" ? `מ"ר` : "Sqm"}
                    </NewText>
                  </UnitsDiv>
                )}
                {!!residentialUnits && (
                  <UnitsDiv>
                    <UnitsIcon />
                    <NewText
                      component={"span"}
                      desktopFontSize={16}
                      desktopLineHeight={"24px"}
                      desktopFontWeight={600}
                      mobileFontSize={16}
                      mobileLineHeight={"20px"}
                      color={colors.black}
                    >
                      {residentialUnits} {locale === "he" ? `יח׳ דיור` : "Housing units"}
                    </NewText>
                  </UnitsDiv>
                )}
              </StyledUnit>
              <StyledLink>{locale === "he" ? <LeftArrow /> : <RightArrow />}</StyledLink>
            </StyledBottom>
          </StyledContent>
        </StyledBottomDiv>
      </StyledCard>
    </StyledLinkWrap>
  )
}

export default ProjectCard
