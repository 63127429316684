"use client"
import styled from "@emotion/styled"

import { IconButton } from "@mui/material"
import { useLocale } from "next-intl"
import { useRef, useState } from "react"
import { extractImage, extractProperty, getUmbracoMediaUrl } from "utils"
import NewText from "./NewText/NewText"
import { Desktop, Mobile } from "./common/bit/MediaQueries"
import { BannerBottomIcon, BannerTopIcon } from "./icons"
import VideoMuteIcon from "./icons/video/VideoMuteIcon"
import VideoPauseIcon from "./icons/video/VideoPauseIcon"
import VideoPlayIcon from "./icons/video/VideoPlayIcon"
import VideoUnmuteIcon from "./icons/video/VideoUnmuteIcon"
import { sharedColors } from "./theme/colors"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
  padding-bottom: 50px;
`

const StyledActiveImage = styled.div`
  opacity: 1;
  position: relative;
  height: 100%;
`

const StyledImagesContainer = styled.div<{
  hideOnDesktop: boolean
  hideOnMobile: boolean
}>`
  height: ${(p) => (p.hideOnMobile ? "0px" : "100%")};
  flex-shrink: 1;
`

const StyledVideoContainer = styled.div`
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`
const StyledVideo = styled.video`
  /* position: absolute; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  margin-top: -60px;
  overflow: hidden;
  flex-shrink: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: -90px;
  }
`

const StyledTextWrapper = styled.div<{ locale: string }>`
  padding: 24px;
  padding: 24px;
  background: ${(props) =>
    props.locale === "en"
      ? `linear-gradient(180deg, #1d4973 0%, #3684cc 116.35%)`
      : `linear-gradient(180deg, #1d4973 100%, #3684cc 116.35%)`};

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  /* min-height: 230px; */
  height: auto;
  isolation: isolate;
  width: 90%;
  position: relative;
  overflow: hidden;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 24px 48px 24px 48px;
    /* width: 70%; */
    width: 848px;
  }
`

const StyledTopIcon = styled.div<{ $locale: string }>`
  position: absolute;
  right: ${(p) => (p.$locale === "he" ? "0px" : "unset")};
  top: 0;
  left: ${(p) => (p.$locale === "he" ? "unset" : "0px")};
`

const StyledBottomIcon = styled.div<{ $locale: string }>`
  position: absolute;
  right: ${(p) => (p.$locale === "he" ? "0px" : "unset")};
  top: 150px;
  left: ${(p) => (p.$locale === "he" ? "unset" : "0px")};
  ${(props) => props.theme.breakpoints.up("md")} {
    top: unset;
    bottom: -15px;
  }
`

const StyledButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
  button {
    padding: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    top: unset;
    bottom: 0px;
    right: 16px;
  }
`

const StyledRichDiv = styled.div`
  margin-top: 12px;
  p {
    background-color: transparent !important;
    text-align: center !important;
    margin: 0;
    color: white !important;
  }
  span {
    color: white !important;
  }
`

type Props = {
  data: any
  noContent?: boolean
  children?: JSX.Element
  attachments?: JSX.Element
}

export const Hero: React.FC<Props> = ({ data, noContent }) => {
  let object = data
  if (data.propertyName === "hEROBanner") {
    object = data.propertyValue.blocks[0]
  }
  const desktopImage = extractImage(object, "desktopImage")
  const mobileImage = extractImage(object, "mobileImage")
  const videoOptional = extractProperty(object, "videoOptional")
  const videoMobile = extractProperty(object, "videoMobile")
  const mainTitle = extractProperty(object, "mainTitle")
  const description = extractProperty(object, "description")
  const [isPlaying, setIsPlaying] = useState(true)
  const [isMuted, setIsMuted] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const mobileRef = useRef<HTMLVideoElement>(null)
  const locale = useLocale()
  const handlePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
      } else {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
    if (mobileRef.current) {
      if (isPlaying) {
        mobileRef.current.pause()
        setIsPlaying(false)
      } else {
        mobileRef.current.play()
        setIsPlaying(true)
      }
    }
  }

  const handleMute = () => {
    if (videoRef.current) {
      if (isMuted) {
        videoRef.current.muted = false
        setIsMuted(false)
      } else {
        videoRef.current.muted = true
        setIsMuted(true)
      }
    }
    if (mobileRef.current) {
      if (isMuted) {
        mobileRef.current.muted = false
        setIsMuted(false)
      } else {
        mobileRef.current.muted = true
        setIsMuted(true)
      }
    }
  }
  return (
    <StyledContainer>
      <StyledImagesContainer
        hideOnDesktop={!videoOptional && !desktopImage?.url}
        hideOnMobile={!videoMobile && !mobileImage?.url}
      >
        <StyledActiveImage>
          <StyledVideoContainer>
            <Mobile sx={{ height: "100%", width: "100%" }}>
              <StyledVideo autoPlay loop muted playsInline ref={mobileRef}>
                <source src={getUmbracoMediaUrl(videoMobile)} type="video/mp4" />
              </StyledVideo>
            </Mobile>
            <Desktop sx={{ height: "100%", width: "100%" }}>
              <StyledVideo autoPlay loop muted playsInline ref={videoRef}>
                <source src={getUmbracoMediaUrl(videoOptional)} type="video/mp4" />
              </StyledVideo>
            </Desktop>
            <StyledButtonContainer>
              <IconButton
                aria-label={isPlaying ? "עצירת הוידאו" : "ניגון הוידאו"}
                onClick={() => handlePlay()}
                disableRipple
              >
                {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
              </IconButton>
              <IconButton
                aria-label={isMuted ? "השמעת הקול בוידאו" : "השתקת הקול בוידאו"}
                onClick={() => handleMute()}
                disableRipple
              >
                {isMuted ? <VideoMuteIcon /> : <VideoUnmuteIcon />}
              </IconButton>
            </StyledButtonContainer>
          </StyledVideoContainer>
          {/* {!!videoOptional && (
            <Desktop>
              <StyledVideoContainer>
                <StyledVideo autoPlay loop muted playsInline ref={videoRef}>
                  <source
                    src={getUmbracoMediaUrl(videoOptional)}
                    type="video/mp4"
                  />
                </StyledVideo>
                <StyledButtonContainer>
                  <IconButton
                    aria-label={isPlaying ? "עצירת הוידאו" : "ניגון הוידאו"}
                    onClick={() => handlePlay()}
                    disableRipple
                  >
                    {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
                  </IconButton>
                  <IconButton
                    aria-label={
                      isMuted ? "השמעת הקול בוידאו" : "השתקת הקול בוידאו"
                    }
                    onClick={() => handleMute()}
                    disableRipple
                  >
                    {isMuted ? <VideoUnmuteIcon /> : <VideoMuteIcon />}
                  </IconButton>
                </StyledButtonContainer>
              </StyledVideoContainer>
            </Desktop>
          )}
          {!!videoMobile && (
            <Mobile>
              <StyledVideoContainer>
                <StyledVideo autoPlay loop muted playsInline ref={mobileRef}>
                  <source
                    src={getUmbracoMediaUrl(videoMobile)}
                    type="video/mp4"
                  />
                </StyledVideo>
                <StyledButtonContainer>
                  <IconButton
                    aria-label={isPlaying ? "עצירת הוידאו" : "ניגון הוידאו"}
                    onClick={() => handlePlay()}
                    disableRipple
                  >
                    {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
                  </IconButton>
                  <IconButton
                    aria-label={
                      isMuted ? "השמעת הקול בוידאו" : "השתקת הקול בוידאו"
                    }
                    onClick={() => handleMute()}
                    disableRipple
                  >
                    {isMuted ? <VideoUnmuteIcon /> : <VideoMuteIcon />}
                  </IconButton>
                </StyledButtonContainer>
              </StyledVideoContainer>
            </Mobile>
          )}
          {!videoOptional && desktopImage?.url && (
            <Desktop>
              <ImageWithAlt
                style={{ objectFit: "cover" }}
                image={desktopImage}
                fill
                priority
              />
            </Desktop>
          )}
          {!videoMobile && mobileImage?.url && (
            <Mobile>
              <ImageWithAlt
                style={{ objectFit: "cover" }}
                image={mobileImage}
                fill
                priority
              />
            </Mobile>
          )} */}
        </StyledActiveImage>
      </StyledImagesContainer>
      {!noContent && (
        <StyledDiv>
          <StyledTextWrapper locale={locale}>
            <StyledTopIcon $locale={locale}>
              <BannerTopIcon />
            </StyledTopIcon>
            <StyledBottomIcon $locale={locale}>
              <BannerBottomIcon />
            </StyledBottomIcon>
            <NewText
              component={"h1"}
              desktopFontSize={48}
              desktopFontWeight={600}
              desktopLineHeight={1.2}
              mobileFontSize={40}
              color={sharedColors.white}
            >
              {mainTitle}
            </NewText>
            <StyledRichDiv>
              <NewText
                component={"span"}
                desktopFontSize={18}
                desktopFontWeight={400}
                desktopLineHeight={1.3}
                mobileFontSize={16}
                mobileLineHeight={1.3}
                color={sharedColors.white}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </StyledRichDiv>
          </StyledTextWrapper>
        </StyledDiv>
      )}
    </StyledContainer>
  )
}

export default Hero
