"use client"
import { createTheme } from "@mui/material/styles"
import { GlobalStyle } from "./GlobalStyle"
import { sharedColors } from "./colors"

export const sharedTheme = createTheme({
  direction: "rtl", // Set initial direction (will be changed dynamically)
  //theme customizations
  typography: {
    // fontFamily: ["RAG-Sans-1.2", "__Rubik_Fallback_ad2eb6"].join(","),
  },
  palette: {
    primary: {
      main: sharedColors.black,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: GlobalStyle,
    },
    MuiButtonBase: {
      defaultProps: {
        // disableRipple: true, // Disabling ripple effect globally
      },
      styleOverrides: {
        root: {
          "&:focus-visible": {
            border: `2px dashed white`,
            outlineOffset: "2px",
            outline: "3px dashed #10059f",
          },
        },
      },
    },
    MuiFilledInput: {
      // Target the FilledInput component
      styleOverrides: {
        root: {
          boxSizing: "border-box",
          borderBottom: "none",
          outline: "1px solid black",

          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "#000000", // Color of the border
          borderRadius: "4px", //

          backgroundColor: "#FFF", // Set your desired background color
          "&:hover": {
            backgroundColor: "#FFF", // Optional: Background color on hover
            outline: "2px solid black",
          },
          "&.Mui-focused": {
            backgroundColor: "#FFF", // Optional: Background color when focused
          },
          "&.Mui-error": {
            border: "1px solid #C92418",
            outline: "none",
            "&:hover": {
              outline: "none",
            },
          },

          // Target the root element of the FilledInput component
          "&:after": {
            // Target the ':after' pseudo-element
            borderBottom: "none !important", // Apply the style override
          },
          "&:before": {
            borderBottom: "none !important",
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          // Target the filled variant
          "&.MuiTextField-root": {
            backgroundColor: "#FFF", // Example background color for the filled style
            borderRadius: 4, // Optional: if you want rounded borders

            // Apply a border around the TextField
          },
        },
      },
    },
    MuiInputLabel: {
      // Target the InputLabel component
      styleOverrides: {
        root: {
          // Define styles for different states
          color: "#6F6F6F", // Default label color
          top: "3px",
          "&.Mui-focused": {
            color: "#6F6F6F", // Color when the input is focused
          },
          "&.Mui-error": {
            color: "#6F6F6F", // Color when there's an error
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          height: "48px",
          "&:focus-visible": {
            border: `2px dashed white`,
            outlineOffset: "2px",
            outline: "3px dashed #10059f",
          },
        },
        sizeLarge: {
          fontSize: "18px",
          lineHeight: "22px",
          padding: "13px 23px",
        },
        sizeSmall: {
          minWidth: "153px",
          fontSize: "16px",
          lineHeight: "20px",
          padding: "8px 16px",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            borderRadius: "200px",
          },
        },
        {
          props: { variant: "text" },
          style: {
            textDecoration: "underline",
            textDecorationThickness: "1px",
            backgroundColor: "transparent",
            color: "#000",
            "&:hover": {
              textDecoration: "underline",
              background: "transparent",
            },
            "&:active": {
              color: "#6F6F6F",
              textDecoration: "underline",
              textDecorationThickness: "2px",
            },
            "&:disabled": {
              textDecoration: "none",
              color: "#6F6F6F",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            border: "2px solid var(--Black, #000)",

            borderRadius: "200px",
            "&:hover,&:focus-visible": {
              border: `2px solid ${sharedColors.oldBrandBlue}`,
              backgroundColor: sharedColors.oldBrandBlue,
              boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.16)",
              color: "#fff",
            },

            "&:active": {
              // background: "#F7F7F7",
              border: "2px solid var(--Black, #000)",
            },
            "&:disabled": {
              border: "none",
              color: "#6F6F6F",
              backgroundColor: "#F7F7F7",
            },
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          // Hide the default bottom border
          display: "none",
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          alignItems: "flex-end",
          "&.MuiInput-root::before": {
            borderBottom: `1px solid ${sharedColors.darkBlue}`,
            opacity: 0.3,
          },
          "&.Mui-error::before": {
            borderBottom: `1px solid ${sharedColors.red}`,
            opacity: 1,
          },

          "&.MuiInput-root::after": {
            borderBottom: `1px solid ${sharedColors.blue}`,
          },

          "&.Mui-error::after": {
            borderBottom: `1px solid ${sharedColors.red}`,
            opacity: 1,
          },
        },
        input: {
          fontSize: "16px",
          lineHeight: "24px",
          "&:focus::placeholder": {
            fontWeight: "500",
          },
          "&::placeholder": {
            fontSize: "16px",
            lineHeight: "24px",
            color: sharedColors.black,
            fontWeight: "400",
            opacity: 1,
          },
        },
        inputMultiline: {},
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: sharedColors.red,
          lineHeight: "20px",
          fontWeight: 400,
          position: "absolute",
          bottom: "-20px",
          margin: 0,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "0px",
          // borderBottom: "4px solid #FCBB00",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&:hover": {},
        },
        checked: {},
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1366,
      xl: 1601,
    },
  },
})
export type Theme = typeof sharedTheme
